import {
    MYACCOUNT_DASHBOARD,
    MYACCOUNT_INBOX,
    MYACCOUNT_MYPROPERTY,
    MYACCOUNT_CALENDAR,
    MYACCOUNT_PROFILE,
    // MYACCOUNT_SEARCH_RESULT_SALES,
    // MYACCOUNT_NEWS_OVERVIEW,
    FRONTEND_SITE_URL,
    WEBSITE_URL,
    MYACCOUNT_LIGHT
} from "./src/core/constants/urls";

import { createSearchUrl } from "./src/core/components/MyAccount/pages/properties/functions";

import CustomLogoIcon from './src/assets/icons/bentleyhurst/logo.inline.svg';

// Heading definitions h1 - h6 + line heights
const h1Size = "4rem", // 1rem === htmlFontSize
      h1LineHSize = 1.1, // lineHeight / fontSize
      h2Size = "3.5rem",
      h2LineHSize = 1.3,
      h3Size = "2.5rem",
      h3LineHSize = 1.3,
      h4Size = "2.25rem",
      h4LineHSize = 1.3,
      h5Size = "1.5rem",
      h5LineHSize = 1.3,
      h6Size = "1.5rem",
      h6LineHSize = 1.4;

// body size custom definitions
const bodyXSmallSize = "0.6875rem",
      lineHeightBXSmallSize = 1.63,
      bodySmallSize = "0.875rem",
      lineHeightBSmallSize = 1.43,
      bodyMediumSize = "1rem",
      lineHeightBMediumSize = 1.5,
      bodyLargeSize = "1.125rem",
      lineHeightBLargeSize = 1.44,
      bodyXLargeSize = "1.375rem",
      lineHeightBXLargeSize = 1.55;

// Custom grid column/container values
const gridItemPmobileValue = "1rem",
      gridItemPtabletValue = "2.5rem",
      gridItemPdesktopValue = "1.875rem";
    //   gridItemPdesktopValue = "3.75rem";

const shadowKeyUmbraOpacity = 0.1;
const shadowKeyPenumbraOpacity = 0.1;
const shadowAmbientShadowOpacity = 0.1;
function createShadow(...px) {
    return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0,0,0,${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0,0,0,${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(0,0,0,${shadowAmbientShadowOpacity})`,
    ].join(',');
}

const propertyLink  = (p) => {
    let sectorPath = p.sector || p.property_type || p.search_type;
    if (sectorPath)
        sectorPath = sectorPath.replace(' ', '-');
    if ('to-let' === sectorPath)
        sectorPath = 'to-rent'
    return `${FRONTEND_SITE_URL}/property-${sectorPath}/${p.slug}-${p.objectID || p.id}`
}

const themeName = 'bentleyhurst';
const termsSiteLink = `${FRONTEND_SITE_URL}/terms-and-conditions`;
const privacySiteLink = false;
const defaultArea = 'Manchester';
const defaultCountry = 'GB';
// const currencyCode = 'EUR';
// const currencySymbol = '€';


const resultLinkFun = (params) => {
    return `${FRONTEND_SITE_URL}${createSearchUrl(params, {useStatus:true, defaultArea: defaultArea})}`;
}

export default {
    // Redefine the custom heading values so we have them available from the {theme} object
    h1: h1Size,
    h1LineH: h1LineHSize,
    h2: h2Size,
    h2LineH: h2LineHSize,
    h3: h3Size,
    h3LineH: h3LineHSize,
    h4: h4Size,
    h4LineH: h4LineHSize,
    h5: h5Size,
    h5LineH: h5LineHSize,
    h6: h6Size,
    h6LineH: h6LineHSize,

    // Redefine the custom body values so we have them available from the {theme} object
    bodyXSmall: bodyXSmallSize,
    lineHeightBXSmall: lineHeightBXSmallSize,
    bodySmall: bodySmallSize,
    lineHeightBSmall: lineHeightBSmallSize,
    bodyMedium: bodyMediumSize,
    lineHeightBMedium: lineHeightBMediumSize,
    bodyLarge: bodyLargeSize,
    lineHeightBLarge: lineHeightBLargeSize,
    bodyXLarge: bodyXLargeSize,
    lineHeightBXLarge: lineHeightBXLargeSize,

    // Redefine custom grid column/container values so we have them available from the {theme} object
    gridItemPmobile: `${gridItemPmobileValue}`,
    gridItemPtablet: `${gridItemPtabletValue}`,
    gridItemPdesktop: `${gridItemPdesktopValue}`,

    /**
     * MUI GLOBAL OVERRIDES OF STYLES
     */
    mixins: (theme) => ({
        toolbar: {
            minHeight: 60,
            [theme.breakpoints.up('md')]: {
                minHeight: 96
            },
        },
    }),
    palette: {
        primary: {
            // light: '#757ce8',
            main: "#C8102E",
            dark: '#1A2440',
            heading: "#232125",
            title: "#1A2440",
            icon: "#C8102E",
            contrastText: '#fff',
        },
        secondary: {
            main: "#C8102E",
            dark: '#1B1434',
            contrastText: '#fff',
        },
        divider: "#D8D8D8",
        background: {
            paper: "#FAFAFA",
            default: "#FAFAFA",
        },
        grey: {
            // custom grey values
            grey1: "#33353A",
            grey2: "#898989",
            grey3: "#BDBDBD",
            grey4: "#F4F6F7",
            grey5: "#FAFAFA",
            grey6: "#BDBDBD",
        },
        text: {
            primary: "#555965",
        },
        icon: {
            loginLogo: '#1A2440'
        }
    },
    shadows: [
        'none',
        createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
        createShadow(0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0), // elevation={2} is custom changed for module bgs
        createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
        createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
        createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
        createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
        createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
        createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
        createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
        createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
        createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
        createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
        createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
        createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
        createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
        createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
        createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
        createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
        createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
        createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
        createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
        createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
        createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
        createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
    ],
    shape: {
        borderRadius: 6,
    },
    spacing: factor => factor !== 'auto' ? `${0.50 * factor}rem` : factor, // this translates to 8px
    components: {
        MuiContainer: { // this <Container> has to be the parent of Mui Grid `container` item element
            styleOverrides: {
                maxWidthXl: {
                    paddingLeft: `${gridItemPmobileValue}`,
                    paddingRight: `${gridItemPmobileValue}`,
                    "@media screen and (min-width: 600px)": {
                        paddingLeft: `${gridItemPtabletValue}`,
                        paddingRight: `${gridItemPtabletValue}`,
                    },
                    "@media screen and (min-width: 1280px)": {
                        paddingLeft: `calc(${gridItemPdesktopValue} * 2)`,
                        paddingRight: `calc(${gridItemPdesktopValue} * 2)`,
                    },
                }
            },
        },
        MuiGrid: {
            styleOverrides: {
                // I've selected the spacing={1} to be a custom theme spacing grid for the use of the project
                // We change the horizontal spacings of items on different breakpoints and Remove vertical spacing
                // In the project each component should ideally be inside the grid item so the spacings are used consistently
                container: {
                    "&.MuiGrid-spacing-xs-1": {
                        width: `calc(100% + (${gridItemPmobileValue} * 2))`,
                        margin: `0 -${gridItemPmobileValue}`,
                        "@media screen and (min-width: 600px)": {
                            width: `calc(100% + (${gridItemPtabletValue} * 2))`,
                            margin: `0 -${gridItemPtabletValue}`,
                        },
                        "@media screen and (min-width: 1280px)": {
                            width: `calc(100% + (${gridItemPdesktopValue} * 2))`,
                            margin: `0 -${gridItemPdesktopValue}`,
                        },
                        "> .MuiGrid-item": {
                            padding: `0 ${gridItemPmobileValue}`,
                            "@media screen and (min-width: 600px)": {
                                padding: `0 ${gridItemPtabletValue}`,
                            },
                            "@media screen and (min-width: 1280px)": {
                                padding: `0 ${gridItemPdesktopValue}`,
                            },
                        }
                    },
                },
            },
        },
        MuiTypography: { // adding custom defined typography variants to use them as `props`
            variants: [
                {
                    props: { variant: 'bodyXSmall' },
                    style: {
                        fontSize: bodyXSmallSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBXSmallSize,
                    },
                },
                {
                    props: { variant: 'bodySmall' },
                    style: {
                        fontSize: bodySmallSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBSmallSize,
                    },
                },
                {
                    props: { variant: 'bodyMedium' },
                    style: {
                        fontSize: bodyMediumSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBMediumSize,
                    },
                },
                {
                    props: { variant: 'bodyLarge' },
                    style: {
                        fontSize: bodyLargeSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBLargeSize,
                    },
                },
                {
                    props: { variant: 'bodyXLarge' },
                    style: {
                        fontSize: bodyXLargeSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBXLargeSize,
                    },
                },
            ],
        },
        MuiButton: {
            defaultProps: {
                // variant: "contained",
            },
            styleOverrides: {
                root: {
                    borderRadius: "100px",
                    textTransform: "uppercase",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 600,
                    "@media (min-width: 960px)": {
                        width: "auto",

                    },
                },
                label: {
                    // fontFamily: '"GillSansNova-SemiBold", "Helvetica", "Arial", sans-serif',
                    // letterSpacing: "0.03em",
                },
                containedPrimary: {
                    background: "#C8102E",
                    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                },
                containedSecondary: {
                    background: "#C8102E",
                    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.15)",
                },
                sizeMedium: {
                    padding: "10px 26px",
                },
                sizeSmall: {
                    fontSize: "12px",
                    "@media (min-width: 991px)": {
                        fontSize: "14px",
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'white' },
                    style: {
                        background: "#fff",
                        boxShadow: "none",
                        fontWeight: "bold",
                        "&:hover": {
                            background: "linear-gradient(225deg, #1663B3 0%, #217698 100%)",
                            color: "#fff",
                        },
                    },
                },
            ],
        },
        MuiPickersCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    "fontSize": bodySmallSize,
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "fontSize": bodySmallSize,
                },
            },
        },
        MuiClock: {
            styleOverrides: {
                root: {
                    "position": "relative", // Fix to position AM/PM inside the clock area
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
            },
            styleOverrides: {
                root: {
                    '& label.Mui-focused': {
                        color: '#1A2440',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#1A2440',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: '#1A2440',
                        },
                      },
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
        },
        MuiFormControl: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    "backgroundColor": "#005480",
                    "padding": "12px 16px",
                },
                arrow: {
                    "&::before": {
                        "backgroundColor": "#005480",
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: 'large',
            },
        },
    },
    typography: {
        htmlFontSize: 16,
        fontFamily: [  // for custom google font you need to include the <link> to the font in the header of the page, this is not done with MUI
            'Eina01',
            'sans-serif',
        ].join(','),
        fontWeightBold: 600,
        h1: {
            fontSize: h1Size,
            fontWeight: 400,
            letterSpacing: "0",
            lineHeight: h1LineHSize, // lineHeight / fontSize
            color: "#1A2440",
        },
        h2: {
            fontSize: h2Size,
            fontWeight: 400,
            letterSpacing: "0",
            lineHeight: h2LineHSize,
            color: "#1A2440",
        },
        h3: {
            fontSize: h3Size,
            fontWeight: 400,
            letterSpacing: "0",
            lineHeight: h3LineHSize,
            color: "#1A2440",
        },
        h4: {
            fontSize: h4Size,
            fontWeight: 400,
            letterSpacing: "0",
            lineHeight: h4LineHSize,
            color: "#1A2440",
        },
        h5: {
            fontSize: h5Size,
            fontWeight: 400,
            letterSpacing: "0",
            lineHeight: h5LineHSize,
            color: "#1A2440",
        },
        h6: {
            fontSize: h6Size,
            fontWeight: 600,
            letterSpacing: "0",
            lineHeight: h6LineHSize,
            color: "#1A2440",
        },
        body1: {
            fontSize: "1.125rem",
            letterSpacing: "0",
            lineHeight: 1.44,
        },
        caption: {
            fontSize: "0.6875rem",
        },
    },

    // Defining global google fonts to be added in the <head>
    // googleFont: "https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,400;0,700;1,400;1,700&display=swap",
    primaryFontFamily: '"Eina01", "Helvetica", "Arial", sans-serif',
    // secondaryFontFamily: '"Eina01", "Helvetica", "Arial", sans-serif',

    // Global CSS implemneted by StarberryRootThemeProvider.
    "sx": {
        "@global": {
            "iframe": {
                "border": "none",
            },
            "body.floatingContainer-toolbar main": { // add additional spacing on bottom if we have floating toolbars
                "@media (max-width: 959px)": {
                    "paddingBottom": "10rem",
                },
            },
            "@font-face": [
                {
                    "fontFamily": "Eina01",
                    "src": "url('/fonts/Eina01/Regular.woff2') format('woff2'), url('/fonts/Eina01/Regular.woff') format('woff')",
                    "fontWeight": 400,
                    "fontStyle": "normal",
                },
                {
                    "fontFamily": "Eina01",
                    "src": "url('/fonts/Eina01/SemiBold.woff2') format('woff2'), url('/fonts/Eina01/SemiBold.woff') format('woff')",
                    "fontWeight": 600,
                    "fontStyle": "normal",
                }
            ],
        },
    },

    /**
     * END MUI GLOBAL OVERRIDES OF STYLES
     */


    /**
    * Starberry custom theme variables
    */

    // Sidebar drawer navigation
    "myAccountSetDrawerWidth": 340,

    // Main content area paddings
    "myAccountContentPTmobile": 2.25, // top spacing for the content container
    "myAccountContentPBmobile": 2.25, // bottom spacing for the content container

    "myAccountContentPTtablet": 4, // top spacing for the content container
    "myAccountContentPBtablet": 4, // bottom spacing for the content container

    "myAccountContentPTDesktop": 6, // top spacing for the content container
    "myAccountContentPBDesktop": 6, // bottom spacing for the content container

    // module border radius if its different then MUI border radius
    "moduleBorderRadius": "14px",
    // Global sidebar module padding
    "modulePadding": theme => theme.spacing(4,5),
    "moduleSpacing": theme => theme.spacing(2.6),
    "moduleSpacingSm": theme => theme.spacing(6),
    "moduleSpacingMd": theme => theme.spacing(6),
    "moduleSpacingLg": theme => theme.spacing(7),
    "moduleSpacingXl": theme => theme.spacing(7),

    /**
    * End Starberry custom theme variables
    */

    // The name of the theme.  This is used for naming elements in the
    // site.
    "name": themeName,

    // This is the theme name that helps to load theme specific items
    "themeName": themeName,

    // Site terms & privacy link
    "termsSiteLink": termsSiteLink,
    "privacySiteLink": privacySiteLink,

    // The company name, which can be used for the copyright message, and
    // anywhere else it might be needed.
    "companyName": "Bentley Hurst Estate Agents",

    // The copyright message of the site, for the footer.
    "copyright": theme => `Copyright © ${theme.year}, ${theme.companyName}`,

    // Simple abstraction to make the copyright message above a little
    // easier to read.
    "year": `${(new Date()).getFullYear()}`,

    // Used by <Breadcrumbs>
    "breadcrumbSeparator": "… ",

    // Social media links used by <FooterSocial>
    "social": {
        "Instagram": "https://www.instagram.com/starberrylife/",
        "Twitter": "https://twitter.com/starberry",
        "YouTube": "https://www.youtube.com/channel/UCAPHSlQISzaD-384O__9QhQ",
        "LinkedIn": "https://www.linkedin.com/company/starberry"
    },

    "images": {
        "default": { method: "img" },
        "propertyresult": {
            "method": "img",
            "sx": {
                "background": "black",
                "& img": {
                    "width": "100%",
                    "height": "auto",
                    "transform": "scale(1)",
                    "transition": "transform .2s ease-out",
                },
                "&:hover": {
                    "transform": "scale(1.3)",
                },
            }
        }
    },
    "loginBg": `/images/${themeName}/login-signup.jpg`,
    "logo": `
        <svg width="197" height="32" viewBox="0 0 197 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.8904 19.4428C38.3027 19.4428 38.6438 19.3117 38.9138 19.0478C39.1846 18.7836 39.3202 18.4649 39.3202 18.0911C39.3202 17.7182 39.1846 17.3959 38.9138 17.126C38.6438 16.8561 38.3027 16.7206 37.8904 16.7206H35.2264V19.4428H37.8904ZM36.945 14.114C37.3564 14.114 37.6975 13.9821 37.9683 13.718C38.2385 13.4541 38.3739 13.1352 38.3739 12.7623C38.3739 12.3887 38.2385 12.0673 37.9683 11.7962C37.6975 11.5263 37.3564 11.3909 36.945 11.3909H35.2264V14.114H36.945ZM32.1365 8.57178H37.2921C37.8459 8.57178 38.3703 8.67809 38.8659 8.89073C39.3612 9.10314 39.7959 9.39546 40.1694 9.76925C40.5423 10.1428 40.8355 10.5775 41.0482 11.0721C41.2606 11.5684 41.3669 12.0928 41.3669 12.6465C41.3669 13.1352 41.2796 13.5765 41.1055 13.9691C40.9324 14.3617 40.6968 14.7062 40.4009 15.0021C40.9803 15.3105 41.4466 15.7392 41.8007 16.286C42.1548 16.8337 42.332 17.474 42.332 18.2077C42.332 18.7742 42.2257 19.3049 42.0133 19.8003C41.8007 20.2956 41.505 20.7269 41.1252 21.0944C40.7456 21.4613 40.3042 21.7502 39.8028 21.9629C39.3003 22.1753 38.7595 22.2816 38.1801 22.2816H32.1365V8.57178Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M45.8847 8.57202H54.3807V11.6235H48.9747V13.9403H53.2227V16.9136H48.9747V19.2313H54.3807V22.2819H45.8847V8.57202Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M61.3131 15.1177V22.2818H58.2231V8.41748L66.874 15.7357V8.57192H69.9633V22.436L61.3131 15.1177Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M76.5676 11.6232H73.2847V8.57178H82.9592V11.6232H79.6566V22.2816H76.5676V11.6232Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M86.2798 8.57202H89.3697V19.2116H94.6985V22.2819H86.2798V8.57202Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M98.0196 8.57202H106.515V11.6235H101.109V13.9403H105.358V16.9136H101.109V19.2313H106.515V22.2819H98.0196V8.57202Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M113.932 16.5277L108.853 8.57178H112.464L115.476 13.4378L118.488 8.57178H122.099L117.021 16.5277V22.2816H113.932V16.5277Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M132.275 8.49585H135.365V13.8641H140.849V8.49585H143.938V22.2057H140.849V16.8374H135.365V22.2057H132.275V8.49585Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M151.518 21.9545C150.829 21.6844 150.236 21.2978 149.741 20.7956C149.246 20.2942 148.859 19.6926 148.582 18.9906C148.305 18.2894 148.167 17.5069 148.167 16.6445V8.49585H151.256V16.6832C151.256 17.5196 151.489 18.1797 151.951 18.6623C152.415 19.1449 153.026 19.3866 153.787 19.3866C154.546 19.3866 155.157 19.1449 155.621 18.6623C156.084 18.1797 156.316 17.5196 156.316 16.6832V8.49585H159.405V16.6445C159.405 17.5069 159.267 18.2894 158.99 18.9906C158.713 19.6926 158.327 20.2942 157.831 20.7956C157.336 21.2978 156.744 21.6844 156.055 21.9545C155.366 22.2254 154.61 22.3599 153.787 22.3599C152.962 22.3599 152.206 22.2254 151.518 21.9545Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M168.46 14.038C168.873 14.038 169.214 13.906 169.484 13.6421C169.754 13.378 169.889 13.0592 169.889 12.6863C169.889 12.3125 169.754 11.9911 169.484 11.7203C169.214 11.4504 168.873 11.3149 168.46 11.3149H166.723V14.038H168.46ZM166.723 16.9916V22.2057H163.633V8.49585H168.808C169.361 8.49585 169.886 8.60217 170.382 8.81458C170.877 9.02721 171.312 9.31953 171.685 9.6931C172.058 10.0669 172.352 10.5013 172.563 10.996C172.776 11.4922 172.882 12.0169 172.882 12.5706C172.882 13.0205 172.811 13.4552 172.67 13.8735C172.528 14.2916 172.325 14.6748 172.062 15.0229C171.798 15.37 171.479 15.676 171.105 15.9399C170.733 16.204 170.32 16.3934 169.87 16.5091L173.636 22.2057H170.16L166.723 16.9916Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M177.807 21.6843C176.956 21.2335 176.294 20.564 175.817 19.6761L178.154 17.7646C178.45 18.2405 178.827 18.6434 179.284 18.9715C179.74 19.2999 180.208 19.4635 180.683 19.4635C181.559 19.4635 181.996 19.1354 181.996 18.4786C181.996 18.1702 181.874 17.9027 181.629 17.6773C181.385 17.4526 180.973 17.2111 180.394 16.953L179.621 16.6059C178.579 16.1421 177.784 15.5405 177.237 14.8007C176.69 14.0601 176.417 13.2261 176.417 12.2997C176.417 11.7083 176.532 11.1673 176.764 10.6781C176.995 10.1886 177.305 9.77022 177.69 9.42217C178.077 9.07501 178.521 8.80776 179.023 8.62176C179.525 8.43487 180.046 8.34131 180.587 8.34131C181.347 8.34131 182.031 8.46061 182.644 8.69876C183.255 8.93714 183.85 9.39061 184.43 10.0598L182.344 12.2612C181.996 11.8359 181.697 11.5599 181.446 11.4306C181.195 11.3019 180.909 11.2376 180.587 11.2376C180.329 11.2376 180.088 11.3182 179.863 11.4794C179.638 11.6405 179.525 11.8753 179.525 12.184C179.525 12.5448 179.676 12.8404 179.979 13.0719C180.281 13.3042 180.748 13.5614 181.378 13.8443L182.112 14.1724C183.065 14.5977 183.802 15.1512 184.323 15.8337C184.845 16.5159 185.105 17.3335 185.105 18.2859C185.105 18.9039 184.996 19.4635 184.777 19.9657C184.558 20.4673 184.256 20.8957 183.869 21.2498C183.483 21.6039 183.023 21.8772 182.489 22.0702C181.954 22.2638 181.372 22.3598 180.742 22.3598C179.634 22.3598 178.656 22.1353 177.807 21.6843Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M190.609 11.5473H187.326V8.49585H197V11.5473H193.698V22.2057H190.609V11.5473Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.97089 28.9729L14.3559 24.1629L14.4148 24.1087L14.5681 23.9677L14.5688 23.7594L14.5921 17.4441L14.5939 16.816H2.75836V17.4425V23.7871V24.0011L2.91795 24.1434L8.33545 28.9732L8.65328 29.2565L8.97089 28.9729ZM3.23556 14.9504H14.1252H14.6008L14.6024 14.4748L14.6232 8.03956L14.6241 7.81752L14.4549 7.67405L14.3011 7.54333L8.96731 2.87632L8.65418 2.60258L8.34015 2.8752L2.92265 7.57981L2.75836 7.72262V7.94018V14.4732V14.9504H3.23556ZM8.67925 0.405273L13.8577 4.62217L13.9873 4.72692L14.3891 5.06669L16.4644 6.80269L16.4628 9.97185V10.4085V21.1345L16.463 21.2525L16.4628 21.2644L16.4369 24.7348L13.7733 27.0433L8.60986 31.3495L3.4981 27.003L0.890747 24.7462V22.3087L0.899252 22.3163V21.2648V10.4249L0.932155 7.01443L3.54086 4.68148L8.67925 0.405273Z" fill="white"/>
        </svg>
    `,

    "logoSecondary": `
        <svg width="197" height="32" viewBox="0 0 197 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.8904 19.4428C38.3027 19.4428 38.6438 19.3117 38.9138 19.0478C39.1846 18.7836 39.3202 18.4649 39.3202 18.0911C39.3202 17.7182 39.1846 17.3959 38.9138 17.126C38.6438 16.8561 38.3027 16.7206 37.8904 16.7206H35.2264V19.4428H37.8904ZM36.945 14.114C37.3564 14.114 37.6975 13.9821 37.9683 13.718C38.2385 13.4541 38.3739 13.1352 38.3739 12.7623C38.3739 12.3887 38.2385 12.0673 37.9683 11.7962C37.6975 11.5263 37.3564 11.3909 36.945 11.3909H35.2264V14.114H36.945ZM32.1365 8.57178H37.2921C37.8459 8.57178 38.3703 8.67809 38.8659 8.89073C39.3612 9.10314 39.7959 9.39546 40.1694 9.76925C40.5423 10.1428 40.8355 10.5775 41.0482 11.0721C41.2606 11.5684 41.3669 12.0928 41.3669 12.6465C41.3669 13.1352 41.2796 13.5765 41.1055 13.9691C40.9324 14.3617 40.6968 14.7062 40.4009 15.0021C40.9803 15.3105 41.4466 15.7392 41.8007 16.286C42.1548 16.8337 42.332 17.474 42.332 18.2077C42.332 18.7742 42.2257 19.3049 42.0133 19.8003C41.8007 20.2956 41.505 20.7269 41.1252 21.0944C40.7456 21.4613 40.3042 21.7502 39.8028 21.9629C39.3003 22.1753 38.7595 22.2816 38.1801 22.2816H32.1365V8.57178Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M45.8847 8.57202H54.3807V11.6235H48.9747V13.9403H53.2227V16.9136H48.9747V19.2313H54.3807V22.2819H45.8847V8.57202Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M61.3131 15.1177V22.2818H58.2231V8.41748L66.874 15.7357V8.57192H69.9633V22.436L61.3131 15.1177Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M76.5676 11.6232H73.2847V8.57178H82.9592V11.6232H79.6566V22.2816H76.5676V11.6232Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M86.2798 8.57202H89.3697V19.2116H94.6985V22.2819H86.2798V8.57202Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M98.0196 8.57202H106.515V11.6235H101.109V13.9403H105.358V16.9136H101.109V19.2313H106.515V22.2819H98.0196V8.57202Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M113.932 16.5277L108.853 8.57178H112.464L115.476 13.4378L118.488 8.57178H122.099L117.021 16.5277V22.2816H113.932V16.5277Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M132.275 8.49585H135.365V13.8641H140.849V8.49585H143.938V22.2057H140.849V16.8374H135.365V22.2057H132.275V8.49585Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M151.518 21.9545C150.829 21.6844 150.236 21.2978 149.741 20.7956C149.246 20.2942 148.859 19.6926 148.582 18.9906C148.305 18.2894 148.167 17.5069 148.167 16.6445V8.49585H151.256V16.6832C151.256 17.5196 151.489 18.1797 151.951 18.6623C152.415 19.1449 153.026 19.3866 153.787 19.3866C154.546 19.3866 155.157 19.1449 155.621 18.6623C156.084 18.1797 156.316 17.5196 156.316 16.6832V8.49585H159.405V16.6445C159.405 17.5069 159.267 18.2894 158.99 18.9906C158.713 19.6926 158.327 20.2942 157.831 20.7956C157.336 21.2978 156.744 21.6844 156.055 21.9545C155.366 22.2254 154.61 22.3599 153.787 22.3599C152.962 22.3599 152.206 22.2254 151.518 21.9545Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M168.46 14.038C168.873 14.038 169.214 13.906 169.484 13.6421C169.754 13.378 169.889 13.0592 169.889 12.6863C169.889 12.3125 169.754 11.9911 169.484 11.7203C169.214 11.4504 168.873 11.3149 168.46 11.3149H166.723V14.038H168.46ZM166.723 16.9916V22.2057H163.633V8.49585H168.808C169.361 8.49585 169.886 8.60217 170.382 8.81458C170.877 9.02721 171.312 9.31953 171.685 9.6931C172.058 10.0669 172.352 10.5013 172.563 10.996C172.776 11.4922 172.882 12.0169 172.882 12.5706C172.882 13.0205 172.811 13.4552 172.67 13.8735C172.528 14.2916 172.325 14.6748 172.062 15.0229C171.798 15.37 171.479 15.676 171.105 15.9399C170.733 16.204 170.32 16.3934 169.87 16.5091L173.636 22.2057H170.16L166.723 16.9916Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M177.807 21.6843C176.956 21.2335 176.294 20.564 175.817 19.6761L178.154 17.7646C178.45 18.2405 178.827 18.6434 179.284 18.9715C179.74 19.2999 180.208 19.4635 180.683 19.4635C181.559 19.4635 181.996 19.1354 181.996 18.4786C181.996 18.1702 181.874 17.9027 181.629 17.6773C181.385 17.4526 180.973 17.2111 180.394 16.953L179.621 16.6059C178.579 16.1421 177.784 15.5405 177.237 14.8007C176.69 14.0601 176.417 13.2261 176.417 12.2997C176.417 11.7083 176.532 11.1673 176.764 10.6781C176.995 10.1886 177.305 9.77022 177.69 9.42217C178.077 9.07501 178.521 8.80776 179.023 8.62176C179.525 8.43487 180.046 8.34131 180.587 8.34131C181.347 8.34131 182.031 8.46061 182.644 8.69876C183.255 8.93714 183.85 9.39061 184.43 10.0598L182.344 12.2612C181.996 11.8359 181.697 11.5599 181.446 11.4306C181.195 11.3019 180.909 11.2376 180.587 11.2376C180.329 11.2376 180.088 11.3182 179.863 11.4794C179.638 11.6405 179.525 11.8753 179.525 12.184C179.525 12.5448 179.676 12.8404 179.979 13.0719C180.281 13.3042 180.748 13.5614 181.378 13.8443L182.112 14.1724C183.065 14.5977 183.802 15.1512 184.323 15.8337C184.845 16.5159 185.105 17.3335 185.105 18.2859C185.105 18.9039 184.996 19.4635 184.777 19.9657C184.558 20.4673 184.256 20.8957 183.869 21.2498C183.483 21.6039 183.023 21.8772 182.489 22.0702C181.954 22.2638 181.372 22.3598 180.742 22.3598C179.634 22.3598 178.656 22.1353 177.807 21.6843Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M190.609 11.5473H187.326V8.49585H197V11.5473H193.698V22.2057H190.609V11.5473Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.97089 28.9729L14.3559 24.1629L14.4148 24.1087L14.5681 23.9677L14.5688 23.7594L14.5921 17.4441L14.5939 16.816H2.75836V17.4425V23.7871V24.0011L2.91795 24.1434L8.33545 28.9732L8.65328 29.2565L8.97089 28.9729ZM3.23556 14.9504H14.1252H14.6008L14.6024 14.4748L14.6232 8.03956L14.6241 7.81752L14.4549 7.67405L14.3011 7.54333L8.96731 2.87632L8.65418 2.60258L8.34015 2.8752L2.92265 7.57981L2.75836 7.72262V7.94018V14.4732V14.9504H3.23556ZM8.67925 0.405273L13.8577 4.62217L13.9873 4.72692L14.3891 5.06669L16.4644 6.80269L16.4628 9.97185V10.4085V21.1345L16.463 21.2525L16.4628 21.2644L16.4369 24.7348L13.7733 27.0433L8.60986 31.3495L3.4981 27.003L0.890747 24.7462V22.3087L0.899252 22.3163V21.2648V10.4249L0.932155 7.01443L3.54086 4.68148L8.67925 0.405273Z" fill="white"/>
        </svg>
    `,

    // Subthemes.  The values within a subtheme will overlay a copy of the
    // parent theme.  Subthemes can themselves contain subthemes.
    "subthemes": {
        "siteby": {
            "tweak": 0.075, // Align baseline of font so SiteByStarberry lines up.  This needs to be kludged for each particular site. :(
        },
        "content": {
            "props": {
                "direction": "column",
            },
            "contained": true,
        },
        "header": {
            "contained": true,

            "props": {
                "direction": "row",
                "alignItems": "middle",
                "justify": "space-between",
                "height": "5rem",
                "paddingTop": "1rem"
            },

            "sx": {
                "root": {
                    "backgroundColor": "black"
                }
            },

            "subthemes": {
                "logo": {
                    "props": {
                        "width": "10rem",
                        "justify": "left",
                        "sx": {

                        }
                    }
                },

                "menu": {
                    "type": "burger",
                    "props": {
                        "direction": "row",
                        "alignItems": "left",
                        "justify": "space-around",
                    },

                    "anchorOrigin":    { "horizontal": "center" },
                    "transformOrigin": { "horizontal": "center" },

                    "sx": {
                        "root": {
                            "& .HeaderMenuContainer": {
                                "display": "flex",
                                "flex-direction": "row",
                            },
                            "& .HeaderMenuLevel0.HeaderMenuLink": {
                                // "flex-direction": "row"
                            },
                            "& .HeaderMenuLevel0.HeaderMenuLink span": {
                                "font-weight": 600
                            },
                            "& .HeaderMenuLevel1 span": {
                            }
                        }
                    },

                    "menus": {
                        "Home": "/",
                        "About Us": "/about-us",
                        "Careers": "/careers",
                        "‍Work": "/our-work",
                        "Stories": "/stories",
                        "Contact Us": "/contact-us",
                        "Who We Work With": {
                            "Estate Agents": "/who-we-work-with/estate-agents",
                            "Property Developers": "/who-we-work-with/property-developers",
                            "Proptech": "/who-we-work-with/proptech",
                            "Finance": "/who-we-work-with/finance",
                            "Solicitors & Lawyers": "/who-we-work-with/legal",
                            "Architects": "/who-we-work-with/architects",
                            "Interior Design": "/who-we-work-with/interior-design"
                        },
                        "What We Do": {
                            "Branding": "/what-we-do/branding",
                            "Website Design": "/what-we-do/website-design",
                            "Website Development": "/what-we-do/website-development",
                            "Content Marketing": "/what-we-do/content-marketing",
                            "Video": "/what-we-do/video",
                            "Social Media": "/what-we-do/social-media",
                            "SEO": "/what-we-do/seo",
                            "Digital Advertising": "/what-we-do/digital-advertising",
                            "Email Marketing": "/what-we-do/email-marketing"
                        }
                    }
                }
            }
        },
        "footer": {
            "contained": "outer",
            "props": {
                "direction": "row",
            },

            "sx": {
                "root": {
                    "& a": {
                        "color": "currentColor"
                    },
                    "& ul": {
                        "margin": 0,
                        "padding": 0,
                        "list-style": "none",
                        "display": "block"
                    }
                }
            },

            "titleVariant": "button",
            "colors": {
                "background": "primary",
                "text": "#fff"
            },
            "menu": {
                "menus": {
                    "Menu": {
                        "Home": "/",
                        "About Us": "/about-us",
                        "Careers": "/careers",
                        "‍Work": "/our-work",
                        "Stories": "/stories",
                        "Contact Us": "/contact-us"
                    },
                    "Who We Work With": {
                        "Estate Agents": "/who-we-work-with/estate-agents",
                        "Property Developers": "/who-we-work-with/property-developers",
                        "Proptech": "/who-we-work-with/proptech",
                        "Finance": "/who-we-work-with/finance",
                        "Solicitors & Lawyers": "/who-we-work-with/legal",
                        "Architects": "/who-we-work-with/architects",
                        "Interior Design": "/who-we-work-with/interior-design"
                    },
                    "What We Do": {
                        "Branding": "/what-we-do/branding",
                        "Website Design": "/what-we-do/website-design",
                        "Website Development": "/what-we-do/website-development",
                        "Content Marketing": "/what-we-do/content-marketing",
                        "Video": "/what-we-do/video",
                        "Social Media": "/what-we-do/social-media",
                        "SEO": "/what-we-do/seo",
                        "Digital Advertising": "/what-we-do/digital-advertising",
                        "Email Marketing": "/what-we-do/email-marketing"
                    },
                    "Follow Us": {
                        "Instagram": "https://www.instagram.com/starberrylife/",
                        "Twitter": "https://twitter.com/starberry",
                        "YouTube": "https://www.youtube.com/channel/UCAPHSlQISzaD-384O__9QhQ",
                        "LinkedIn": "https://www.linkedin.com/company/starberry"
                    }
                }
            }
        },
        "menuRouterHomePage": { // Specify which homepage version layout we want to use
            "props": {
                "pageVariant": MYACCOUNT_LIGHT ? "MyProperty" : "Dashboard"
            },
        },
        "bookingWidget":{ // options: src/core/components/MyAccount/property/components/iframeBookViewing/defaults.js
        },
        "imageBox": { // options: src/components/MyAccount/imageBox/defaults.js
        },
        "videoText": { // options: src/components/MyAccount/modules/videoText/defaults.js
        },
        "iconBox": { // options: src/components/MyAccount/iconBox/defaults.js
        },
        "static2column": { // options: src/components/MyAccount/modules/grid/defaults.js
        },
        "bgIcon": { // options: src/components/MyAccount/modules/bgIcon/defaults.js
        },
        "socialShare": { // options: src/components/MyAccount/socialShare/defaults.js
        },
        "backButton": { // options: src/components/MyAccount/backButton/defaults.js
        },
        "projectIcons": { // options: src/components/MyAccount/icons/defaults.js
            "props": {
                "logoIcon": CustomLogoIcon,
            },
        },
        "sidebarDrawer": { // options: src/components/MyAccount/SidebarDrawerDefaults.js
            "props": {
                "drawerMobileHidden": {
                    "mdDown": true,
                    "implementation": "css", // The implementation can be swapped with js to avoid SEO duplication of links.
                },
                "drawerMobile": {
                    "variant": "permanent",
                    "anchor": "left",
                    // "anchor": "right",
                    "open": true,
                },
                "drawerDesktopHidden": {
                    "mdUp": true,
                    "implementation": "css", // The implementation can be swapped with js to avoid SEO duplication of links.
                },
                "drawerDesktop": {
                    "variant": "temporary",
                    // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    "anchor": "left",
                    // "anchor": "right",
                },
                "menus": [
                    {
                        "hide": false,
                        "name": "Back to Website",
                        "icon": {
                            "name": "backToWebsiteIcon",
                            "props": {
                                "color": "primary",
                                "fontSize": "small",
                            },
                        },
                        "link": `${WEBSITE_URL}`,
                        "newtab": true,
                        "menutype": "external",
                    },
                    {
                        "hide": false,
                        "name": "Dashboard",
                        "icon": {
                            // "name": "Home", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
                            "name": "dashboardIcon",
                            "props": {
                                "color": "primary",
                                "fontSize": "small",
                            },
                        },
                        "link": `${MYACCOUNT_DASHBOARD}`,
                    },
                    {
                        "hide": false,
                        "name": "My Property",
                        "icon": {
                            "name": "myPropertyIcon",
                        },
                        "link": `${MYACCOUNT_MYPROPERTY}`,
                    },
                    {
                        "hide": false,
                        "name": "Inbox",
                        "icon": {
                            "name": "inboxIcon",
                        },
                        "link": `${MYACCOUNT_INBOX}`,
                    },
                    {
                        "hide": false,
                        "name": "Calendar",
                        "icon": {
                            "name": "calendarIcon",
                        },
                        "link": `${MYACCOUNT_CALENDAR}`,
                    },
                    {
                        "hide": false,
                        "name": "Profile",
                        "icon": {
                            "name": "profileIcon",
                        },
                        "link": `${MYACCOUNT_PROFILE}`,
                    },
                    {
                        "hide": false,
                        "name": "Log out",
                        "icon": {
                            "name": "logoutIcon",
                        },
                        "link": "#",
                        "funcName": "logout",
                    },
                ],
                "menuListItem": {
                    "button": true,
                    "disableRipple": true,
                    // "divider": true,
                },
            },
            "sx": (theme) => ({
                "& .sidebarDrawer-drawerCommon-root": {
                    "display": 'flex',
                    "minHeight": "100vh",
                },
                "& .sidebarDrawer-drawerCommon-drawer": {
                    [theme.breakpoints.up('md')]: {
                        "width": `${theme.myAccountSetDrawerWidth}px`,
                        "flexShrink": 0,
                    },
                },
                "& .sidebarDrawer-drawerCommon-drawerPaper": {
                    "width": `${theme.myAccountSetDrawerWidth}px`,
                    "backgroundColor": theme.palette.primary.dark,
                    "color": theme.palette.primary.contrastText,
                },
                "& .sidebarDrawer-menuList": {
                    "padding": theme.spacing(0,2),
                    [theme.breakpoints.up('lg')]: {
                        "padding": theme.spacing(0,8),
                    },
                    "& .sidebarDrawer-menuListItem": {
                        "padding": theme.spacing(1.5,0),
                        "opacity": 0.6,
                        "background": "none",
                        "&.Mui-selected": {
                            "opacity": 1,
                            "background": "none",
                        },
                        "&.Mui-selected:hover": {
                            "opacity": 1,
                            "background": "none",
                        },
                        "&:hover": {
                            "opacity": 1,
                            "background": "none",
                        },
                        "& .sidebarDrawer-menuListItemText": {

                        },
                        "& .sidebarDrawer-menuListItemTextPrimary": {
                            "fontSize": "16px",
                            "fontWeight": 700,
                            "lineHeight": "24px",
                        },
                    },
                    "& .sidebarDrawer-menuListItemIcon": {
                        "minWidth": "40px",
                        "color": theme.palette.primary.contrastText,
                    },
                },
            }),
            "subthemes": {
                "logo": {
                    "sx": (theme) => ({
                        "margin": theme.spacing(4, "auto", 5, "auto"),
                        "width": "220px",
                        "height": "auto",
                        [theme.breakpoints.up('lg')]: {
                            "width": "230px",
                            "margin": theme.spacing(5, "auto", 9, "auto"),
                        },
                    }),
                },
            },
        },
        "appBar": { // options: src/components/MyAccount/AppBarDefaults.js
            "props": {
                "burgerButton": {
                    "color": "primary",
                    "aria-label": "open drawer",
                    "edge": "start",
                    "disableRipple": true,
                    "sx": {
                        "py": 0,
                        "marginRight": "0!important",
                        "&:hover": {
                            "background": "transparent",
                        },
                        "& svg": {
                            "width": "auto",
                            "height": "24px",
                        },
                    },
                },
                "menusBottomFixed": [
                    {
                        "id": 1,
                        "hide": false,
                        "name": "Dashboard",
                        "icon": {
                            "name": "dashboardIcon", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
                            "props": {
                                "color": "primary",
                                "fontSize": "small",
                            },
                        },
                        "link": `${MYACCOUNT_DASHBOARD}`,
                    },
                    {
                        "id": 2,
                        "hide": false,
                        "name": "My Property",
                        "icon": {
                            "name": "myPropertyIcon",
                        },
                        "link": `${MYACCOUNT_MYPROPERTY}`,
                    },
                    {
                        "id": 3,
                        "hide": false,
                        "name": "Inbox",
                        "icon": {
                            "name": "inboxIcon",
                        },
                        "link": `${MYACCOUNT_INBOX}`,
                    },
                    {
                        "id": 4,
                        "hide": false,
                        "name": "Calendar",
                        "icon": {
                            "name": "calendarIcon",
                        },
                        "link": `${MYACCOUNT_CALENDAR}`,
                    },
                    {
                        "id": 5,
                        "hide": true    ,
                        "name": "Search",
                        "icon": {
                            "name": "searchIcon",
                        },
                        "link": `${MYACCOUNT_CALENDAR}`,
                    },
                    {
                        "id": 6,
                        "hide": false,
                        "name": "Menu",
                        "icon": {
                            "name": "mobileBurgerIcon",
                        },
                        "link": "#",
                        "funcName": "menu",
                    },
                ]
            },
            "sx": (theme) => ({
                "& .appBar": {
                    "boxShadow": "none",
                    "borderBottom": `1px solid ${theme.palette.divider}`,
                    [theme.breakpoints.up('md')]: {
                        "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`,
                        "marginLeft": `${theme.myAccountSetDrawerWidth}px`,
                        // "marginRight": `${theme.myAccountSetDrawerWidth}px`, // Enable this if Drawer is positioned right
                    },
                },
                "& .appBar-grow": {
                    [theme.breakpoints.up("md")]: {
                        "flexGrow": 1,
                    }
                },
                "& .appBar-sectionDesktop": {
                    "display": "none",
                    [theme.breakpoints.up("md")]: {
                        "display": "flex",
                        "alignItems": "center",
                    },
                },
                "& .appBar-sectionMobile": {
                    "display": "flex",
                    [theme.breakpoints.up("md")]: {
                        "display": "none",
                    },
                },
                "& li.MuiButtonBase-root": {
                    "& > button": { // This should be the icon in the dropdown list
                        "marginRight": theme.spacing(1),
                        "padding": theme.spacing(1, .2)
                    },
                    "& > .appBar-notification": { // This should be the icon in the dropdown list
                        "marginRight": theme.spacing(1),
                    },
                    "& > a": {
                        "flexGrow": 1,
                    },
                },
                "& .appBar-notification": {
                    "color": "rgba(0, 0, 0, 0.54)",
                    "& .appBar-badge": {
                        "color": "#fff",
                        "height": "23px",
                        "minWidth": "24px",
                        "borderRadius": "100%",
                        "border": "2px solid #fff",
                        "backgroundColor": `${theme.palette.primary.main}`,
                    },
                },
                "& .appBar-toolbar": {
                    "paddingLeft": theme.spacing(2.25),
                    "paddingRight": theme.spacing(2.25),
                    [theme.breakpoints.up('md')]: {
                        "paddingLeft": theme.spacing(4.75),
                        "paddingRight": theme.spacing(4.75),
                    },
                    [theme.breakpoints.up('lg')]: {
                        "paddingLeft": theme.spacing(7.5),
                        "paddingRight": theme.spacing(10),
                    },
                },
                "& .appBar-burgerButton": {
                    "marginRight": theme.spacing(2),
                    [theme.breakpoints.up('md')]: {
                        "display": "none",
                    },
                },
                "& .appBar-appBarTitle": {
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    [theme.breakpoints.up("md")]: {
                        "display": "none",
                    },
                },
                "& .appBar-menuListItemMobile": {
                    "justifyContent": "center",
                    "opacity": 0.4,
                    "background": "none",
                    "&.Mui-selected": {
                        "opacity": 1,
                        "background": "none",
                    },
                    "& .appBar-menuListItemMobile-divider": {
                        "borderColor": "black",
                    },
                },
                "& .appBar-menuListItemIconMobile": {
                    "color": theme.palette.primary.contrastText,
                    "minWidth": "inherit",
                },
                "& .appBar-appBarSearch": {
                    "display": "none",
                    "position": "relative",
                    "borderRadius": theme.shape.borderRadius,
                    // "backgroundColor": fade(theme.palette.common.white, 0.15),
                    // '&:hover': {
                    //     "backgroundColor": fade(theme.palette.common.white, 0.25),
                    // },
                    "marginRight": theme.spacing(2),
                    "marginLeft": 0,
                    "width": "100%",
                    "& .back-to-site a": {
                        "display": "inline-flex",
                        "& svg": {
                            "marginRight": "5px"
                        }
                    },
                    [theme.breakpoints.up("sm")]: {
                        // "marginLeft": theme.spacing(3),
                        "width": "auto",
                    },
                    [theme.breakpoints.up("md")]: {
                        "display": "block",
                    },
                    "& .inputRoot": {
                        "color": "inherit",
                    },
                    "& .inputInput": {
                        "padding": theme.spacing(1, 0, 1, 1),
                        // vertical padding + font size from searchIcon
                        "paddingRight": `calc(1em + ${theme.spacing(4)})`,
                        "transition": theme.transitions.create('width'),
                        "width": "100%",
                        "border": "1px solid #d8d8d8",
                        "borderRadius": theme.shape.borderRadius,
                        [theme.breakpoints.up("md")]: {
                            "width": "20ch",
                        },
                    },
                    "& .searchIcon": {
                        "padding": theme.spacing(0, 2),
                        "height": '100%',
                        "position": 'absolute',
                        "top": 0,
                        "right": 0,
                        "pointerEvents": 'none',
                        "display": 'flex',
                        "alignItems": 'center',
                        "justifyContent": 'center',
                        "color": theme.palette.grey2,
                    },
                },
                "& .appBar-userDropdown": {
                    "margin": theme.spacing(0,3.25),
                    "paddingLeft": theme.spacing(1),
                    "paddingRight": theme.spacing(1),
                    "&:hover": {
                        "background": "none",
                    },
                    "&.appBar-label": {
                        "textTransform": "capitalize",
                        "fontSize": theme.bodyMediumSize,
                        "fontWeight": "400",
                        "color": theme.palette.primary.dark,
                    },
                    "&.appBar-text": {
                        "&:hover": {
                            "backgroundColor": "transparent",
                            "color": theme.palette.secondary.main,
                        },
                    },
                },
                "& .appBar-appBarMobile": {
                    "boxShadow": "none",
                    "backgroundColor": theme.palette.primary.dark,
                    "border": "none",
                    "width": "100%",
                    "top": 'auto',
                    "bottom": 0,
                },
                "& .appBar-userAvatar": {
                    "textTransform": "uppercase",
                },
            }),
        },
        "customSidebarDrawer": { // options: src/components/MyAccount/customSidebarDrawer/defaults.js
            "subthemes": {
                "menuListIconsTabs": {
                    "sx": (theme) => ({
                        "& .menuListIconsTabs": {
                            "padding": 0,
                            "margin": 0,
                        },
                        "& .menuListIconsTabs-vertical": {
                        },
                        "& .menuListIconsTabs-indicator": {
                            "display": "none",
                        },
                        "& .menuListIconsTabs-tab": {
                            "fontWeight": "bold",
                            "textTransform": "none",
                            "minHeight": "58px",
                            "borderBottom": `1px solid ${theme.palette.divider}`,
                            "paddingTop": theme.spacing(1.5),
                            "paddingBottom": theme.spacing(1.5),
                            "paddingLeft": theme.gridItemPmobile,
                            "paddingRight": theme.gridItemPmobile,
                            "opacity": 1,
                            [theme.breakpoints.up('sm')]: {
                                "fontSize": theme.bodyMedium,
                                "lineHeight": theme.lineHeightBMedium,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(1.5,0),
                            },
                            "&.menuListIconsTabs-tab-wrapper": {
                                "display": "flex",
                                "flexDirection": "row",
                                "alignItems": "center",
                                "justifyContent": "flex-start",
                            },
                        },
                        "& .menuListIconsTabs-tab-labelIcon svg": {
                            "color": theme.palette.text.primary,
                            "marginBottom": 0,
                            "marginRight": theme.spacing(2.25),
                        }
                    })
                },
            },
        },
        "globalComponentLayout": {
            "sx": (theme) => ({
                "marginBottom": theme.spacing(2.6),
                [theme.breakpoints.up('sm')]: {
                    "marginBottom": theme.spacing(6),
                },
                [theme.breakpoints.up('lg')]: {
                    "marginBottom": theme.spacing(7),
                },
                "& .globalComponentLayout-titleContainer": {
                    "display": "flex",
                    "flexDirection": "row",
                    "alignItems": "flex-end",
                    "minHeight": 56,
                    "paddingBottom": theme.spacing(2),
                    [theme.breakpoints.up('sm')]: {
                        "paddingBottom": theme.spacing(3),
                    },

                },
                "& .globalComponentLayout-title": {
                    "fontWeight": "bold",
                    "color": "#263140",
                    [theme.breakpoints.up('sm')]: {
                        "fontSize": theme.h5,
                        "lineHeight": theme.h5LineH,
                        "color": theme.palette.primary.title,
                    },
                },
                "& .globalComponentLayout-titleLinkMore": {
                    "fontWeight": "bold",
                    "color": theme.palette.grey.grey2,
                    "marginLeft": "auto",
                    [theme.breakpoints.up('sm')]: {
                        "fontSize": theme.bodyMedium,
                        "lineHeight": theme.lineHeightBMedium,
                        "fontWeight": "normal",
                        "color": theme.palette.text.primary,
                    },
                },
            }),
        },
        "globalDropdownActions": { // options: src/components/MyAccount/property/savedSearch/defaultsActions.js
        },
        "globalPageTitle": { // options: src/components/MyAccount/pageTitle/defaults.js
            "props": {
                "pageTitleProps": {
                    "component": "h1",
                    "variant": "h3",
                }
            },
        },
        "globalForm": { // options: src/components/MyAccount/forms/defaults.js
        },
        "mainContent": { // options: src/components/MyAccount/ContentDefaults.js
            "sx": (theme) => ({
                "display": "flex",
                "flexDirection": "column",
                "width": "100%",
                "flexGrow": 1,
                "padding": theme.spacing(theme.myAccountContentPTmobile, 0, (theme.myAccountContentPBmobile + 8), 0),
                [theme.breakpoints.up('sm')]: {
                    "padding": theme.spacing(theme.myAccountContentPTtablet, 0, (theme.myAccountContentPBtablet + 8), 0),
                },
                [theme.breakpoints.up('md')]: {
                    "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`,
                    "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                },
                [theme.breakpoints.up('lg')]: {
                    "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                },
                "& .mainContent-wrapper": {
                    "flexGrow": 1,
                },
                "& .mainContent-toolbar": theme.mixins.toolbar,
            }),
        },
        "alertComponent": { // options: src/components/MyAccount/alert/defaults.js
        },
        "welcomeCard": { // options: src/components/MyAccount/user/welcome/defaults.js
        },
        "circularProgress": { // options: src/components/MyAccount/progress/defaults.js
        },
        "noActions": { // options: src/components/MyAccount/progress/NoActionsDefaults.js
        },
        "formTextField": {
        },
        "formSwitchField": { // options: src/components/MyAccount/forms/FormSwitchField/defaults.js
        },
        "loginSignup": { // options: src/components/MyAccount/modals/defaults.js
            "props": {
                "logoType": "logoSecondary",
                "dialogTitleProps": {
                    "variant": "h6",
                    "component": "h2",
                },
                "signup": {
                    "defaultCountry": defaultCountry,
                    "usePhoneField": true,
                }
            }
        },
        "backToWebsite": {   // options: src/components/MyAccount/modals/backToWebsite/defaults.js
        },
        "logoLogin": {  // options: src/components/MyAccount/Logo/defaults.js
        },
        "actionCardContainer": { // Wrapper around action cards component
        },
        "actionCard": { // options: src/components/MyAccount/actions/action/defaults.js - actionCardContainer/actionCard
            "sx": (theme) => ({
                "&.actionCard-card": {
                    "marginBottom": 0,
                    "paddingTop": theme.spacing(1),
                    "paddingBottom": theme.spacing(1),
                    "&.disabled": {
                        "opacity": 0.5
                    }
                },
                "& .actionCard-cardActionButton": {
                    "backgroundColor": theme.palette.grey.grey4,
                    "color": theme.palette.primary.dark,
                },
                "& .actionCard-cardHeader": {
                    "padding": theme.spacing(1,0),
                    [theme.breakpoints.up('sm')]: {
                        "padding": theme.spacing(1.5,0),
                    },
                    "& .cardHeader-avatar": {
                        "marginLeft": "auto",
                        "marginRight": theme.spacing(1.875),
                        [theme.breakpoints.up('sm')]: {
                            "marginRight": theme.spacing(3.5),
                        }
                    },
                    "& .cardHeader-title": {
                        "color": theme.palette.primary.dark,
                        "fontSize": theme.bodySmall,
                        "fontWeight": theme.typography.fontWeightBold,
                        "marginBottom": theme.spacing(0.25),
                        [theme.breakpoints.up('sm')]: {
                            "fontSize": theme.bodyLarge,
                            "lineHeight": theme.lineHeightBLarge,
                            "marginBottom": theme.spacing(0.5),
                        }
                    },
                    "& .cardHeader-subheader": {
                        "color": "#555965",
                        [theme.breakpoints.up('sm')]: {
                            "fontSize": theme.bodyMedium,
                            "lineHeight": theme.lineHeightBMedium,
                        }
                    },
                    "& .cardHeader-action": {
                        "alignSelf": "center",
                        "marginRight": theme.spacing(0.5),
                        "& button": {
                            "color": theme.palette.secondary.main,
                        },
                    },
                },
            })
        },
        "actionCardViewing": { // override options from `actionCard` subtheme
        },
        "actionCardOffer": { // override options from `actionCard` subtheme
        },
        "actionCardTenancy": { // override options from `actionCardTenancy` subtheme
        },
        "actionCardFeedback": { // override options from `actionCardFeedback` subtheme
        },
        "actionCardDocument": { // override options from `actionCardDocument` subtheme
        },
        "actionCardWorksorder": { // override options from `actionCardWorksorder` subtheme
        },
        "actionCardInspection": { // override options from `actionCardInspection` subtheme
        },
        "actionCardAppointment": { // override options from `actionCardAppointment` subtheme
        },
        "dialogDefault": { // options: src/components/MyAccount/modals/default/defaults.js
        },
        "dialogPreference": { // options: src/components/MyAccount/user/propertyPreference/dialogDefaults.js
        },
        "dialogLogout": {// options: src/components/MyAccount/user/logoutDialog/defaults.js
        },
        "dialogChildren": { // options: src/components/MyAccount/property/components/reportFaultBtn/dialogChildrenDefaults.js
        },
        "actionCardDialog": { // options: src/components/MyAccount/modals/action/defaults.js
            "subthemes": {
                "contactModule": { // Overriding how the contact module renders in action dialog
                    "props": {
                        "cardModule": {
                            "elevation": 0,
                        },
                    },
                    // "sx": (theme) => ({
                    //     "& .contactModule": {
                    //         "padding": 0,
                    //         "borderRadius": 0,
                    //     },
                    //     "& .cardContent": {
                    //         "display": "flex",
                    //         "flexDirection": "column",
                    //         "alignItems": "center",
                    //         "padding": 0,
                    //         "textAlign": "center",
                    //     },
                    //     "& .cardModuleTitle": {
                    //         "marginBottom": theme.spacing(4),
                    //     },
                    //     "& .cardActions": {
                    //         "flexDirection": "column",
                    //         "padding": 0,
                    //         "marginTop": theme.spacing(3),
                    //         "& > div": {
                    //             "width": "100%",
                    //         }
                    //     },
                    //     "& .cardModuleUserTitle": {
                    //         "color": theme.palette.text.primary,
                    //     },
                    //     "& .cardModuleUserJobTitle": {
                    //         "color": theme.palette.text.primary,
                    //     },
                    //     "& .cardModuleButton": {
                    //         "width": "100%",
                    //         "marginTop": theme.spacing(2),
                    //     },
                    //     "& .avatar": {
                    //         "marginBottom": theme.spacing(2),
                    //         // "backgroundColor": "#ff0000",
                    //         "width": 80,
                    //         "height": 80,
                    //     },
                    //     "& .closeCard": {
                    //         "color": theme.palette.grey.grey2,
                    //         "marginTop": theme.spacing(3),
                    //     },
                    // }),
                },
            }
        },
        "actionDialogChildren": { // options: src/components/MyAccount/actions/action/defaults.js
            "subthemes": {
                "globalForm": { // Overriding how the globalForm renders in action dialog
                    "props": {
                        "formButton": {
                            "variant": "contained",
                            "color": "primary",
                            "fullWidth": true,
                        },
                    },
                    "sx": (theme) => ({
                        "& .globalForm-globalFormForm": {
                            "width": "100%",
                        },
                        "& .globalForm-fieldContainer": {
                            "display": "flex",
                            "flexDirection": "column",
                            "marginTop": theme.spacing(3),
                        },
                        "& .globalForm-textField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(2.375),
                        },
                        "& .globalForm-selectField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(3),
                        },
                        "& .globalForm-jsonAutocompleteField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(3),
                        },
                        "& .myaccount-date-time-calendar": {
                            "display": "flex",
                            "flexDirection": "column",
                            "alignItems": "center",
                            "& .react-datepicker": {
                                "marginBottom": theme.spacing(3),
                                "& .react-datepicker__month-container": {
                                    "@media (max-width: 400px)": {
                                        "width": "100%",
                                    },
                                },
                                "& .react-datepicker__navigation--next": {
                                    "@media (max-width: 400px)": {
                                        "right": "2px",
                                    },
                                },
                                "& .react-datepicker__time-container": {
                                    "@media (max-width: 400px)": {
                                        "width": "100%",
                                        "border": "none",
                                    },
                                    "& .react-datepicker__header.react-datepicker__header--time": {
                                        "@media (min-width: 401px)": {
                                            "display": "flex",
                                            "minHeight": "62px",
                                            "alignItems": "center",
                                            "justifyContent": "center",
                                        },
                                    },
                                    "& .react-datepicker__time-box": {
                                        "@media (max-width: 400px)": {
                                            "width": "100%",
                                        },
                                    },
                                    "& li.react-datepicker__time-list-item": {
                                        "display": "flex",
                                        "alignItems": "center",
                                        "&.react-datepicker__time-list-item--disabled": {
                                            "display": "none",
                                        },
                                    },
                                    "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                        "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .react-datepicker__day--selected": {
                                        "backgroundColor": theme.palette.primary.main,
                                        "color": "#fff",
                                        "&:hover": {
                                            "backgroundColor": theme.palette.primary.main,
                                        },
                                    },
                                },
                            },
                        },
                        "& .suggestionsContainer": {
                            "position": "relative",
                            "& .suggestionsContainerOpen": {
                                "position": "absolute",
                                "zIndex": 998,
                                "top": "50px",
                                "left": 0,
                                "right": 0,
                            },
                            "& .suggestionsList": {
                                "margin": 0,
                                "padding": 0,
                                "listStyleType": "none",
                            },
                            "& .suggestion": {
                                "display": "block",
                                "padding": "10px 15px",
                                "&:hover": {
                                    "cursor": "pointer",
                                    "background": theme.palette.grey.grey3,
                                },
                            },
                        },
                        "& .globalForm-fieldsRadio": {
                            "marginBottom": theme.spacing(2),
                        },
                        "& .globalForm-actionContainer": {
                        },
                        "& .globalForm-fieldsTitle": {
                            "marginBottom": theme.spacing(3),
                        },
                        "& .globalForm-formButton": {
                            "marginTop": theme.spacing(2.75),
                        },
                    }),
                },
            },
        },
        "activityCard": { // options: src/components/MyAccount/activities/defaults.js
        },
        "profileCard": { // options: src/components/MyAccount/user/profileCard/defaults.js
        },
        "userAvatar": { // options: src/components/MyAccount/user/avatar/defaults.js
        },
        "tabsMyAccount": { // options: src/components/MyAccount/tabs/defaults.js
            "sx": (theme) => ({
                "flexGrow": 1,
                "marginBottom": theme.moduleSpacing,
                [theme.breakpoints.up('sm')]: {
                    "marginBottom": theme.moduleSpacingSm,
                },
                [theme.breakpoints.up('lg')]: {
                    "marginBottom": theme.moduleSpacingLg,
                },
                "& .tabsMyAccount-tabs": {
                    "borderBottom": "1px solid #d8d8d8",
                    "marginLeft": `-${theme.gridItemPmobile}`,
                    "marginRight": `-${theme.gridItemPmobile}`,
                    [theme.breakpoints.up('sm')]: {
                        "marginLeft": `-${theme.gridItemPtablet}`,
                        "marginRight": `-${theme.gridItemPtablet}`,
                        "paddingLeft": theme.gridItemPtablet,
                        "paddingRight": theme.gridItemPtablet,
                    },
                    [theme.breakpoints.up('lg')]: {
                        "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                        "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                        "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                        "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                    },
                    "& .tabsMyAccount-indicator": {
                        "height": "3px",
                        "backgroundColor": theme.palette.primary.title,
                    },
                    "& .tabsMyAccount-scrollButtons": {
                        "alignItems": "flex-start",
                        "& svg": {
                            "marginTop": "12px",
                        },
                        "&.Mui-disabled": {
                            "opacity": 0.3,
                        },
                    },
                },
                "& .tabsMyAccount-tab": {
                    "color": theme.palette.primary.title,
                    "textTransform": "none",
                    [theme.breakpoints.up('sm')]: {
                        "minWidth": "auto",
                        "paddingLeft": 0,
                        "paddingRight": 0,
                        "marginRight": theme.spacing(5),
                    },
                    [theme.breakpoints.up('md')]: {
                        "paddingBottom":  theme.spacing(3.5),
                    },
                    "&.tabsMyAccount-selected": {
                        "color": theme.palette.primary.dark,
                        "fontWeight": theme.typography.fontWeightBold,
                    },
                    "&.tabsMyAccount-wrapper": {
                        "fontSize": theme.bodySmall,
                        [theme.breakpoints.up('sm')]: {
                            "fontSize": theme.bodyLarge,
                        }
                    },
                },
                "& .tabsMyAccount-tabPanel": {
                    "padding": 0,
                },
                "& .tabsMyAccount-tabPanelBox": {
                    "padding": theme.spacing(2.75,0,0,0),
                }
            }),
            "subthemes": {
                // If we're nesting tabs inside another Tab component we need to change the styling of the inner Tab component
                // this changes the child tab component styling
                "tabsMyAccount": {
                    "tabs": {
                        "props": {
                            "textColor": "primary",
                            "aria-label": "tabs",
                            "variant": "scrollable",
                            "scrollButtons": "auto",
                        },
                        "sx": (theme) => ({
                            "root": {
                                "borderBottom": "1px solid #d8d8d8",
                                "marginLeft": `-${theme.gridItemPmobile}`,
                                "marginRight": `-${theme.gridItemPmobile}`,
                                [theme.breakpoints.up('sm')]: {
                                    "marginLeft": `-${theme.gridItemPtablet}`,
                                    "marginRight": `-${theme.gridItemPtablet}`,
                                    "paddingLeft": theme.gridItemPtablet,
                                    "paddingRight": theme.gridItemPtablet,
                                },
                                [theme.breakpoints.up('md')]: {
                                    "borderBottom": "none",
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                    "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                    "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                    "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                                },
                            },
                            "indicator": {
                                "height": "3px",
                            },
                            "scrollButtons": {
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        }),
                    },
                    "tab": {
                        "sx": (theme) => ({
                            "root": {
                                "textTransform": "none",
                                [theme.breakpoints.up('sm')]: {
                                    "paddingLeft": 0,
                                    "paddingRight": 0,
                                    "marginRight": theme.spacing(5),
                                }
                            },
                            "selected": {
                                "fontWeight": theme.typography.fontWeightBold,
                            },
                            "wrapper": {
                                "fontSize": theme.bodySmallSize,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.typography.h6.fontSize,
                                }
                            },
                        }),
                    },
                },
            },
        },
        "calendar": { // options: src/components/MyAccount/calendar/defaults.js
        },
        "simpleModule": { // options: src/components/MyAccount/simpleModule/defaults.js
            "props": {
                "initialStep":'stepOne',
                "cardModuleTitleProps": {
                    "variant": "h6",
                },
            },
            "sx": (theme) => ({
                "& .simpleModule-cardModule": {
                    "borderRadius": theme.moduleBorderRadius,
                },
                "& .simpleModule-cardModule-media": {
                    "height": 140,
                },
                "& .simpleModule-cardContent": {
                    "padding": theme.modulePadding,
                },
                "& .simpleModule-cardActions": {
                    "padding": theme.modulePadding,
                    "paddingTop": "0!important",
                },
                "& .simpleModule-cardModuleTitle": {
                    "fontWeight": theme.typography.fontWeightBold,
                    "marginBottom": theme.spacing(1),
                },
                "& .simpleModule-cardModuleContent": {
                    "color": theme.palette.grey.grey2,
                },
                "& .simpleModule-cardModuleButton": {
                    "width": "100%",
                },
            }),
        },
        "contactModule": { // options: src/components/MyAccount/contactModule/defaults.js
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-jsonAutocompleteField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .myaccount-date-time-calendar": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                    "alignItems": "center",
                                    "& .react-datepicker": {
                                        "marginBottom": theme.spacing(3),
                                        "& .react-datepicker__month-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                            },
                                        },
                                        "& .react-datepicker__navigation--next": {
                                            "@media (max-width: 400px)": {
                                                "right": "2px",
                                            },
                                        },
                                        "& .react-datepicker__time-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                                "border": "none",
                                            },
                                            "& .react-datepicker__header.react-datepicker__header--time": {
                                                "@media (min-width: 401px)": {
                                                    "display": "flex",
                                                    "minHeight": "62px",
                                                    "alignItems": "center",
                                                    "justifyContent": "center",
                                                },
                                            },
                                            "& .react-datepicker__time-box": {
                                                "@media (max-width: 400px)": {
                                                    "width": "100%",
                                                },
                                            },
                                            "& li.react-datepicker__time-list-item": {
                                                "display": "flex",
                                                "alignItems": "center",
                                                "&.react-datepicker__time-list-item--disabled": {
                                                    "display": "none",
                                                },
                                            },
                                            "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                            "& .react-datepicker__day--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                                "color": "#fff",
                                                "&:hover": {
                                                    "backgroundColor": theme.palette.primary.main,
                                                },
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "simpleContactModule": { // options: src/components/MyAccount/contactModule/simpleContact.js
        },
        "menuListIcons": { // options: src/components/MyAccount/iconMenuList/menuListIcons.js
            "menuListItemText": {
                "primaryTypographyProps": {
                    "props": {
                        "variant": "bodySmall",
                    },
                },
                "secondaryTypographyProps":{
                    "props": {
                        "variant": "bodyXSmall"
                    },
                },
                "sx": (theme) => ({
                    "root": {
                    },
                    "primary": {
                        "color": theme.palette.primary.heading,
                        "fontWeight": "bold",
                        [theme.breakpoints.up('sm')]: {
                            "fontSize": theme.bodyMedium,
                            "lineHeight": theme.lineHeightBMedium,
                        },
                    },
                    "secondary": {
                    },
                }),
            },
            "menuListItemIcon": {
                "sx": {
                    "root": (theme) => ({
                        "minWidth": "40px",
                        "color": theme.palette.primary.icon,
                    }),
                },
            },
            "menuListItemSecondaryIcon": {
                "sx": {
                    "root": (theme) => ({
                        "color": theme.palette.primary.icon,
                    }),
                },
            },
        },
        "menuListIconsTabs": { // options: src/components/MyAccount/iconMenuList/menuListIconsTabs.js
        },
        "savedProperties": {
            "props": {
                "resultLinkFun": (p) => resultLinkFun(p),
                "noActionMessage": "Get started in just a few seconds",
            },
            "sx": (theme) => ({
                "& .box-container": {
                    "display": "flex",
                    "flexWrap": "wrap",
                    "alignItems": "center",
                    "border": "1px solid #DDDDDD",
                    "borderRadius": "4px",
                    "padding": theme.spacing(5),
                    "& .box-image": {
                        "background": theme.palette.grey.grey3,
                        "& svg": {
                            "fontSize": "2rem",
                            "color": theme.palette.primary.dark
                        }
                    },
                },
                "& .box-image-container": {
                    "display": "none",
                    [theme.breakpoints.up('lg')]: {
                        "display": "block"
                    },
                },
                "& .box-image-container, .box-btn-container, .box-content": {
                    [theme.breakpoints.down('lg')]: {
                        "flexBasis": "100%",
                        "maxWidth": "100%",
                    },
                },
                "& .box-content": {
                    [theme.breakpoints.down('lg')]: {
                        "padding-bottom": "10px",
                        "flexBasis": "100%",
                        "maxWidth": "100%",
                    },
                    [theme.breakpoints.up('lg')]: {
                        "paddingLeft": "10px",
                    },
                },
                "& .box-btn-container": {
                    "textAlign": "left",
                    "paddingTop": theme.spacing(1),
                    [theme.breakpoints.up('lg')]: {
                        "textAlign": "right",
                        "paddingTop": theme.spacing(0),
                    }
                },
                "& .box-container-title": {
                    "fontFamily": theme.primaryFontFamily,
                    "fontWeight": theme.typography.fontWeightBold,
                    "fontSize": theme.bodyMediumSize,
                    "lineHeight": theme.h5LineH,
                    "marginBottom": "5px",
                    "color": theme.palette.primary.dark,
                    [theme.breakpoints.up('xl')]: {
                        "marginBottom": "10px",
                    },
                },
                "& .box-container-text": {
                    "fontFamily": theme.secondaryFontFamily,
                    "fontSize": theme.bodyMedium,
                    "lineHeight": theme.lineHeightBMedium,
                    "fontWeight": "normal",

                    [theme.breakpoints.down('md')]: {
                        "marginBottom": "5px",
                        "display": "block"
                    },
                    "color": theme.palette.grey.grey2
                }
            }),
        },
        "propertyResultComponent": {
            "subthemes": {
                "results": { // default property result styling
                    "sx": (theme) => ({
                        [theme.breakpoints.up('sm')]: {
                            "width": "auto",
                            "margin": theme.spacing(0,-0.75),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "margin": theme.spacing(0,-2.375),
                        },
                    }),
                    "subthemes": {
                        "propertyresult": {
                            "props": {
                                "container": {
                                    "order": ['media', 'header', 'aside'],
                                    "sx": (theme) => ({
                                        "flexDirection": "row",
                                        "width": "100%",
                                        "paddingTop": theme.spacing(1.125),
                                        "paddingBottom": theme.spacing(1.125),
                                        [theme.breakpoints.up('sm')]: {
                                            "flexDirection": "column",
                                            "width": `calc(50% - ${theme.spacing(1.5)})`,
                                            "paddingTop": 0,
                                            "paddingBottom": 0,
                                            "marginBottom": theme.spacing(5),
                                            "marginLeft": theme.spacing(0.75),
                                            "marginRight": theme.spacing(0.75),
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                                            "marginLeft": theme.spacing(2.375),
                                            "marginRight": theme.spacing(2.375),
                                        },
                                    }),
                                },
                                "header": {
                                    "sx": (theme) => ({
                                        "width": "calc(100% - 110px)",
                                        [theme.breakpoints.up('sm')]: {
                                            "width": "100%",
                                            "marginTop": theme.spacing(3.25),
                                        },
                                        "& .propertyresult-address": {
                                            "fontWeight": "bold",
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyLarge,
                                                "lineHeight": theme.lineHeightBLarge,
                                                "marginBottom": theme.spacing(0.25),
                                            },
                                            "& .propertyresult-link-image": {
                                                "color": "#292929",
                                            },
                                        },
                                        "& .propertyresult-price": {
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyMedium,
                                                "lineHeight": theme.lineHeightBMedium,
                                                // "marginBottom": theme.spacing(1.25),
                                            },
                                        },
                                    }),
                                },
                                "price": {
                                    "in": "header",
                                    "variant": "bodySmall",
                                },
                                "address": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                    "component": "header",
                                },
                                "offer": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                },
                                "rooms": {
                                    "in": null,
                                },
                                // "save": {
                                //     "in": "aside",
                                // },
                                // "removeProperty": {
                                //     "in": "aside",
                                // },
                                "media": {
                                    "sx": (theme) => ({
                                        "overflow": "hidden",
                                        "marginRight": theme.spacing(2.375),
                                        [theme.breakpoints.up('sm')]: {
                                            "marginRight": 0,
                                        },
                                        "&.propertyresult-media": {
                                            [theme.breakpoints.up('sm')]: {
                                                "width": "100%",
                                            },
                                        },
                                        "& .propertyresult-link-image": {
                                            "display": "block",
                                        },
                                        "& .propertyresult-image": {
                                            "display": "block",
                                            "width": "90px",
                                            [theme.breakpoints.up('sm')]: {
                                                "width": "100%",
                                            }
                                        },
                                    })
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            // "address": {
                            //     "separator": " • ",
                            //     "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                            // },
                        },
                    },
                },
            },
        },
        "savedPropertyResultComponent":{
            "subthemes": {
                "results": { // default property result styling
                    "sx": (theme) => ({
                        [theme.breakpoints.up('sm')]: {
                            "width": "auto",
                            "margin": theme.spacing(0,-0.75),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "margin": theme.spacing(0,-2.375),
                        },
                    }),
                    "subthemes": {
                        "propertyresult": {
                            "props": {
                                "container": {
                                    "order": ['media', 'header', 'aside'],
                                    "sx": (theme) => ({
                                        "flexDirection": "column",
                                        "width": "100%",
                                        "marginBottom": theme.spacing(3),
                                        [theme.breakpoints.up('sm')]: {
                                            "width": `calc(50% - ${theme.spacing(1.5)})`,
                                            "marginBottom": theme.spacing(5),
                                            "marginLeft": theme.spacing(0.75),
                                            "marginRight": theme.spacing(0.75),
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                                            "marginLeft": theme.spacing(2.375),
                                            "marginRight": theme.spacing(2.375),
                                        },
                                    }),
                                },
                                "header": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "marginTop": theme.spacing(1.5),
                                        [theme.breakpoints.up('sm')]: {
                                            "marginTop": theme.spacing(3.25),
                                        },
                                        "& .propertyresult-address": {
                                            "fontWeight": "bold",
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyLarge,
                                                "lineHeight": theme.lineHeightBLarge,
                                                "marginBottom": theme.spacing(0.25),
                                            },
                                            "& .propertyresult-link-image": {
                                                "color": "#292929",
                                            },
                                        },
                                        "& .propertyresult-rooms": {
                                            // "paddingBottom": theme.spacing(1.25),
                                        },
                                    }),
                                },
                                "aside": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "display": "flex",
                                        "flexDirection": "row",
                                        "alignItems": "center",
                                        "justifyContent": "flex-end",
                                        "& .propertyresult-price": {
                                            "marginRight": "auto",
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyMedium,
                                                "lineHeight": theme.lineHeightBMedium,
                                            },
                                        },
                                    }),
                                },
                                "price": {
                                    "in": "aside",
                                    "variant": "bodySmall",
                                    "component": "p",
                                },
                                "address": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                    "component": "header",
                                },
                                "offer": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                },
                                "rooms": {
                                    "in": "header",
                                },
                                // "save": {
                                //     "in": "aside",
                                // },
                                "removeProperty": {
                                    "in": "media",
                                },
                                "media": {
                                    "sx": {
                                        "position": "relative",
                                        "overflow": "hidden",
                                        "&.propertyresult-media": {
                                            "width": "100%",
                                        },
                                        "& .propertyresult-link-image": {
                                            "display": "block",
                                            "&:after": {
                                                "content": "''",
                                                "position": "absolute",
                                                "top": 0,
                                                "left": 0,
                                                "width": "100%",
                                                "height": "100%",
                                                "background": "rgba(0,0,0,0.2)",
                                                "zIndex": "2",
                                                "pointerEvents": "none",
                                            },
                                        },
                                        "& .propertyresult-image": {
                                            "display": "block",
                                            "width": "100%",
                                        },
                                        "& .propertyresult-removeProperty": {
                                            "position": "absolute",
                                            "top": "10px",
                                            "right": "10px",
                                            "zIndex": "20",
                                            "& svg": {
                                                "color": "#fff",
                                            },
                                        },
                                    },
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            "addressFormat": {
                                "separator": " , ",
                                "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift()
                                // "values": (p) => console.log(p)
                            },
                            "roomsFormat": {
                                "separator": " ",
                                "values": (p) => [p.bedrooms,p.receptions,p.bathrooms]
                            },
                            "linkFormat": {
                                "separator": "-",
                                // "target":"_blank",
                                "values": (p) => propertyLink(p)
                                // "values": (p) => console.log(p)
                            },
                            // "lettingsPricePrefix": "",
                            "lettingsPriceSuffix": " PCM",
                            // "salesPricePrefix": "",
                            // "salesPriceSuffix": "",
                        },
                    },
                },
                "bookViewingBtn": {
                    "props": {
                        // "apiCallFun": false,
                        "btnLabelProps": "Book viewing online",
                        "showBtnIcon": true,
                        "btnProps": {
                            "color": "primary",
                            "variant": "text",
                            "size": "small",
                        }
                    }
                },
            },
        },
        "savedSearchResults": {
            "props": {
                "resultLinkFun": (p) => resultLinkFun(p),
                "noactionTitle": "",
                "noactionMessage": "Looks like you don’t have any searches saved yet",
            }
        },
        "latestProperties": {
            "subthemes": {
                "results": {
                    "subthemes": {
                        "propertyresult": {
                            "props": {
                                "container": {
                                    "direction": "row",
                                    "span": "full",
                                    "order": ['media', 'header'],
                                    "sx": (theme) => ({
                                        "paddingTop": theme.spacing(1.125),
                                        "paddingBottom": theme.spacing(1.125),
                                        "marginBottom": theme.moduleSpacing,
                                        [theme.breakpoints.up('sm')]: {
                                            "paddingTop": theme.spacing(3.375),
                                            "paddingBottom": theme.spacing(3.375),
                                            "marginBottom": theme.moduleSpacingSm,
                                            "borderBottom": `1px solid ${theme.palette.grey.grey4}`,
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            "marginBottom": theme.moduleSpacingLg,
                                        },
                                        "&:last-child": {
                                            "borderBottom": "none",
                                        },
                                    }),
                                },
                                "header": {
                                    "sx": {
                                        "width": "calc(100% - 110px)",
                                        "& .propertyresult-address": {
                                            "fontWeight": "bold",
                                            "& .propertyresult-link-image": {
                                                "color": "#292929",
                                            },
                                        },
                                    },
                                },
                                "price": {
                                    "in": "header",
                                    "variant": "bodySmall",
                                },
                                "address": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                    "component": "header",
                                    "sx": {
                                        "root": {
                                            "fontWeight": "bold"
                                        }
                                    }
                                },
                                "offer": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                },
                                "media": {
                                    "overflow": "hidden",
                                    "width": 90,
                                    "sx": (theme) => ({
                                        "marginRight": theme.spacing(2.375),
                                        "& .propertyresult-link-image": {
                                            "display": "block",
                                        },
                                        "& .propertyresult-image": {
                                            "display": "block",
                                        },
                                    })
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            "address": {
                                "separator": " • ",
                                "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                            },
                        },
                    },
                },
            },
        },
        "singleProperty": {
            "sx": (theme) => ({
                "root": {
                    "padding": theme.spacing(4,5),
                    "borderRadius": theme.moduleBorderRadius,
                    [theme.breakpoints.up('lg')]: {
                        "marginTop": theme.spacing(0),
                    }
                },
            }),
            "subthemes": {
                "results": {
                    "subthemes": {
                        "propertyresult": {
                            "props": {
                                "container": {
                                    "direction": "column",
                                    "order": ['media', 'header'],
                                    // "sx": (theme) => ({
                                    //     "paddingTop": theme.spacing(1.125),
                                    //     "paddingBottom": theme.spacing(1.125),
                                    //     [theme.breakpoints.up('sm')]: {
                                    //         "paddingTop": theme.spacing(3.375),
                                    //         "paddingBottom": theme.spacing(3.375),
                                    //         "borderBottom": `1px solid ${theme.palette.grey.grey4}`,
                                    //     },
                                    //     "&:last-child": {
                                    //         "borderBottom": "none",
                                    //     },
                                    // }),
                                },
                                "header": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "& .propertyresult-address": {
                                            "marginTop": theme.spacing(3.25),
                                            "marginBottom": theme.spacing(0.25),
                                            "fontWeight": "bold",
                                            "& .propertyresult-link-image": {
                                                "color": "#292929",
                                            },
                                        },
                                    }),
                                },
                                "summary": {
                                    "in": "media",
                                    "variant": "bodyMedium",
                                },
                                "address": {
                                    "in": "header",
                                    "variant": "bodyLarge",
                                    "component": "header",
                                },
                                "media": {
                                    "sx": (theme) => ({
                                        "overflow": "hidden",
                                        "position": "relative",
                                        "& .propertyresult-link-image": {
                                            "display": "block",
                                        },
                                        "& .propertyresult-image": {
                                            "display": "block",
                                            "width": "100%",
                                            "height": "auto",
                                        },
                                        "& .propertyresult-summary": {
                                            "textTransform": "capitalize",
                                            "position": "absolute",
                                            "top": 0,
                                            "left": 0,
                                            "width": "100%",
                                            "backgroundColor": "#4F4F4F",
                                            "zIndex": 2,
                                            "textAlign": "center",
                                            "color": "#fff",
                                            "fontWeight": "bold",
                                            "padding": theme.spacing(1),
                                        }
                                    })
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            // "address": {
                            //     "separator": " • ",
                            //     "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                            // },
                        },
                    },
                },
            },
        },
        "myPropertyPageSingleHeaderProperty": {
            "sx": (theme) => ({
                "width": `calc(100% + (${theme.gridItemPmobile} * 2))`,
                "marginLeft": `-${theme.gridItemPmobile}`,
                "marginRight": `-${theme.gridItemPmobile}`,
                "marginBottom": theme.spacing(4),
                [theme.breakpoints.up('sm')]: {
                    "width": `calc(100% + (${theme.gridItemPtablet} * 2))`,
                    "marginLeft": `-${theme.gridItemPtablet}`,
                    "marginRight": `-${theme.gridItemPtablet}`,
                    "marginBottom": 0,
                },
            }),
            "subthemes": {
                "globalComponentLayout": {
                    "sx":{
                        // Remove global space between components and modules
                        "marginBottom": 0,
                    },
                },
                "singleProperty": {
                    "sx": (theme) => ({
                        "root": {
                            "padding": 0,
                            "margin": 0,
                        },
                    }),
                    "subthemes": {
                        "results": {
                            "subthemes": {
                                "propertyresult": {
                                    "props": {
                                        "header": {
                                            "sx": (theme) => ({
                                                "width": "100%",
                                                "& .propertyresult-address": {
                                                    "display": "none",
                                                    "fontWeight": "normal",
                                                    "margin": 0,
                                                    "padding": `0 ${theme.gridItemPtablet}`,
                                                    [theme.breakpoints.up('sm')]: {
                                                        "display": "flex",
                                                        "padding": `0 ${theme.gridItemPtablet}`,
                                                        "marginBottom": theme.spacing(4),
                                                        "marginTop": theme.spacing(3.75),
                                                    },
                                                    "& .propertyresult-link-image": {
                                                        "color": "#292929",
                                                    },
                                                },
                                                "& .propertyresult-offerText": {
                                                    "display": "none",
                                                    [theme.breakpoints.up('md')]: {
                                                        "display": "flex"
                                                    },
                                                },
                                            }),
                                        },
                                        "address": {
                                            "in": "header",
                                            "variant": "h3",
                                            "component": "h2",
                                        },
                                        "media": {
                                            "sx": (theme) => ({
                                                "width": "100%",
                                                "position": "relative",
                                                "overflow": "hidden",
                                                "& .propertyresult-link-image": {
                                                    "display": "block",
                                                },
                                                "& .propertyresult-image": {
                                                    "overflow": "hidden",
                                                    "display": "block",
                                                    "width": "100%",
                                                    "height": "auto",
                                                    "marginBottom": 0,
                                                },
                                                "& .propertyresult-summary": {
                                                    "textTransform": "capitalize",
                                                    "position": "absolute",
                                                    "top": 0,
                                                    "left": 0,
                                                    "width": "100%",
                                                    "backgroundColor": "#4F4F4F",
                                                    "zIndex": 2,
                                                    "textAlign": "center",
                                                    "color": "#fff",
                                                    "fontWeight": "bold",
                                                    "padding": theme.spacing(1),
                                                }
                                            })
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "profileMyAccountForm": { // options: src/components/MyAccount/user/profile/defaults.js
            "subthemes": {
                "globalForm": {
                    "sx": (theme) => ({
                        "& .globalForm-globalFormForm": {
                        },
                        "& .globalForm-fieldContainer": {
                            "display": "flex",
                            "flexDirection": "column",
                        },
                        "& .globalForm-textField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(4),
                            [theme.breakpoints.up('md')]: {
                                "marginBottom": theme.spacing(3),
                            },
                        },
                        "& .globalForm-selectField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(4),
                            [theme.breakpoints.up('md')]: {
                                "marginBottom": theme.spacing(3),
                            },
                        },
                        "& .globalForm-jsonAutocompleteField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(3),
                        },
                        "& .myaccount-date-time-calendar": {
                            "display": "flex",
                            "flexDirection": "column",
                            "alignItems": "center",
                            "& .react-datepicker": {
                                "marginBottom": theme.spacing(3),
                                "& .react-datepicker__month-container": {
                                    "@media (max-width: 400px)": {
                                        "width": "100%",
                                    },
                                },
                                "& .react-datepicker__navigation--next": {
                                    "@media (max-width: 400px)": {
                                        "right": "2px",
                                    },
                                },
                                "& .react-datepicker__time-container": {
                                    "@media (max-width: 400px)": {
                                        "width": "100%",
                                        "border": "none",
                                    },
                                    "& .react-datepicker__header.react-datepicker__header--time": {
                                        "@media (min-width: 401px)": {
                                            "display": "flex",
                                            "minHeight": "62px",
                                            "alignItems": "center",
                                            "justifyContent": "center",
                                        },
                                    },
                                    "& .react-datepicker__time-box": {
                                        "@media (max-width: 400px)": {
                                            "width": "100%",
                                        },
                                    },
                                    "& li.react-datepicker__time-list-item": {
                                        "display": "flex",
                                        "alignItems": "center",
                                        "&.react-datepicker__time-list-item--disabled": {
                                            "display": "none",
                                        },
                                    },
                                    "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                        "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .react-datepicker__day--selected": {
                                        "backgroundColor": theme.palette.primary.main,
                                        "color": "#fff",
                                        "&:hover": {
                                            "backgroundColor": theme.palette.primary.main,
                                        },
                                    },
                                },
                            },
                        },
                        "& .suggestionsContainer": {
                            "position": "relative",
                            "& .suggestionsContainerOpen": {
                                "position": "absolute",
                                "zIndex": 998,
                                "top": "50px",
                                "left": 0,
                                "right": 0,
                            },
                            "& .suggestionsList": {
                                "margin": 0,
                                "padding": 0,
                                "listStyleType": "none",
                            },
                            "& .suggestion": {
                                "display": "block",
                                "padding": "10px 15px",
                                "&:hover": {
                                    "cursor": "pointer",
                                    "background": theme.palette.grey.grey3,
                                },
                            },
                        },
                        "& .globalForm-fieldsRadio": {
                            "marginBottom": theme.spacing(2),
                        },
                        "& .globalForm-actionContainer": {
                        },
                        "& .globalForm-fieldsTitle": {
                            "display": "none",
                            [theme.breakpoints.up('md')]: {
                                "marginBottom": theme.spacing(3),
                            },
                        },
                        "& .globalForm-formButton": {

                        },
                        "& .phonenumber_container": {
                            // "background": 'red',
                            "& .PhoneInputCountryIcon": {
                                "width": "40px",
                                "position": "absolute",
                                "height": "40px",
                                "& img,svg": {
                                    "marginTop": "12px",
                                    "paddingLeft": "10px",
                                    "width": "100%"
                                }
                            },
                            "& .PhoneInputCountrySelect": {
                                "width": "40px",
                                "position": "absolute",
                                "opacity": "0",
                                "zIndex": "2",
                                "height": "40px",
                                "cursor": "pointer",
                            },
                            "& .MuiFormControl-root": {
                                "& input": {
                                    "paddingLeft": "50px"
                                }
                            }
                        }
                    }),
                },
            },
        },
        "profileMyAccountChangePassword": { // options: src/components/MyAccount/user/password/defaults.js
        },
        "profileMyAccountNotificationPreferences":{
        },
        "profileDeleteAccount": {
        },
        "pendingItems": { // options: src/components/MyAccount/progress/defaults.js
            "props": {
                "variant": "withCircle",
            },
        },
        "feedbackProgress": { // options: src/components/MyAccount/feedbackProgress/defaults.js
            "subthemes": {
                "globalComponentLayout": {
                    "componentContainer": {
                        "props": {
                            "elevation": 2,
                        },
                        "sx": (theme) => ({
                            "root": {
                                "padding": 0,
                                "borderRadius": theme.moduleBorderRadius,
                                [theme.breakpoints.down('sm')]: {
                                    "boxShadow": "none",
                                },
                                [theme.breakpoints.up('sm')]: {
                                    "padding": theme.modulePadding,
                                },
                            },
                        }),
                    },
                },
            },
        },
        "swipeEdge": { // options: src/components/MyAccount/customSidebarDrawer/swipeEdgeDefaults.js
            "subthemes": {
                "menuListIconsTabs": {
                    "sx": (theme) => ({
                        "& .menuListIconsTabs": {
                            "padding": 0,
                            "margin": 0,
                        },
                        "& .menuListIconsTabs-vertical": {
                        },
                        "& .menuListIconsTabs-indicator": {
                            "display": "none",
                        },
                        "& .menuListIconsTabs-tab": {
                            "fontWeight": "bold",
                            "textTransform": "none",
                            "minHeight": "58px",
                            "borderBottom": `1px solid ${theme.palette.divider}`,
                            "paddingTop": theme.spacing(1.5),
                            "paddingBottom": theme.spacing(1.5),
                            "paddingLeft": 0,
                            "paddingRight": theme.gridItemPmobile,
                            "opacity": 1,
                            [theme.breakpoints.up('sm')]: {
                                "fontSize": theme.bodyMedium,
                                "lineHeight": theme.lineHeightBMedium,
                                // "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(1.5,0),
                            },
                            "&.menuListIconsTabs-tab-wrapper": {
                                "display": "flex",
                                "flexDirection": "row",
                                "alignItems": "center",
                                "justifyContent": "flex-start",
                            },
                        },
                        "& .menuListIconsTabs-tab-labelIcon svg": {
                            "color": theme.palette.text.primary,
                            "marginBottom": 0,
                            "marginRight": theme.spacing(2.25),
                        }
                    })
                },
            },
        },
        "reportFaultBtn": { // options: src/components/MyAccount/property/components/reportFaultBtn/defaults.js
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-jsonAutocompleteField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .myaccount-date-time-calendar": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                    "alignItems": "center",
                                    "& .react-datepicker": {
                                        "marginBottom": theme.spacing(3),
                                        "& .react-datepicker__month-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                            },
                                        },
                                        "& .react-datepicker__navigation--next": {
                                            "@media (max-width: 400px)": {
                                                "right": "2px",
                                            },
                                        },
                                        "& .react-datepicker__time-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                                "border": "none",
                                            },
                                            "& .react-datepicker__header.react-datepicker__header--time": {
                                                "@media (min-width: 401px)": {
                                                    "display": "flex",
                                                    "minHeight": "62px",
                                                    "alignItems": "center",
                                                    "justifyContent": "center",
                                                },
                                            },
                                            "& .react-datepicker__time-box": {
                                                "@media (max-width: 400px)": {
                                                    "width": "100%",
                                                },
                                            },
                                            "& li.react-datepicker__time-list-item": {
                                                "display": "flex",
                                                "alignItems": "center",
                                                "&.react-datepicker__time-list-item--disabled": {
                                                    "display": "none",
                                                },
                                            },
                                            "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                            "& .react-datepicker__day--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                                "color": "#fff",
                                                "&:hover": {
                                                    "backgroundColor": theme.palette.primary.main,
                                                },
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "newDocumentBtn": { // options: src/components/MyAccount/property/components/reportFaultBtn/defaults.js
            "props" : {
            },
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-jsonAutocompleteField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .myaccount-date-time-calendar": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                    "alignItems": "center",
                                    "& .react-datepicker": {
                                        "marginBottom": theme.spacing(3),
                                        "& .react-datepicker__month-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                            },
                                        },
                                        "& .react-datepicker__navigation--next": {
                                            "@media (max-width: 400px)": {
                                                "right": "2px",
                                            },
                                        },
                                        "& .react-datepicker__time-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                                "border": "none",
                                            },
                                            "& .react-datepicker__header.react-datepicker__header--time": {
                                                "@media (min-width: 401px)": {
                                                    "display": "flex",
                                                    "minHeight": "62px",
                                                    "alignItems": "center",
                                                    "justifyContent": "center",
                                                },
                                            },
                                            "& .react-datepicker__time-box": {
                                                "@media (max-width: 400px)": {
                                                    "width": "100%",
                                                },
                                            },
                                            "& li.react-datepicker__time-list-item": {
                                                "display": "flex",
                                                "alignItems": "center",
                                                "&.react-datepicker__time-list-item--disabled": {
                                                    "display": "none",
                                                },
                                            },
                                            "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                            "& .react-datepicker__day--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                                "color": "#fff",
                                                "&:hover": {
                                                    "backgroundColor": theme.palette.primary.main,
                                                },
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "simpleValuationModule": {
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-jsonAutocompleteField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .myaccount-date-time-calendar": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                    "alignItems": "center",
                                    "& .react-datepicker": {
                                        "marginBottom": theme.spacing(3),
                                        "& .react-datepicker__month-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                            },
                                        },
                                        "& .react-datepicker__navigation--next": {
                                            "@media (max-width: 400px)": {
                                                "right": "2px",
                                            },
                                        },
                                        "& .react-datepicker__time-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                                "border": "none",
                                            },
                                            "& .react-datepicker__header.react-datepicker__header--time": {
                                                "@media (min-width: 401px)": {
                                                    "display": "flex",
                                                    "minHeight": "62px",
                                                    "alignItems": "center",
                                                    "justifyContent": "center",
                                                },
                                            },
                                            "& .react-datepicker__time-box": {
                                                "@media (max-width: 400px)": {
                                                    "width": "100%",
                                                },
                                            },
                                            "& li.react-datepicker__time-list-item": {
                                                "display": "flex",
                                                "alignItems": "center",
                                                "&.react-datepicker__time-list-item--disabled": {
                                                    "display": "none",
                                                },
                                            },
                                            "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                            "& .react-datepicker__day--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                                "color": "#fff",
                                                "&:hover": {
                                                    "backgroundColor": theme.palette.primary.main,
                                                },
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "contactBtn": { // options: src/components/MyAccount/property/components/contactBtn/defaults.js
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-jsonAutocompleteField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .myaccount-date-time-calendar": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                    "alignItems": "center",
                                    "& .react-datepicker": {
                                        "marginBottom": theme.spacing(3),
                                        "& .react-datepicker__month-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                            },
                                        },
                                        "& .react-datepicker__navigation--next": {
                                            "@media (max-width: 400px)": {
                                                "right": "2px",
                                            },
                                        },
                                        "& .react-datepicker__time-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                                "border": "none",
                                            },
                                            "& .react-datepicker__header.react-datepicker__header--time": {
                                                "@media (min-width: 401px)": {
                                                    "display": "flex",
                                                    "minHeight": "62px",
                                                    "alignItems": "center",
                                                    "justifyContent": "center",
                                                },
                                            },
                                            "& .react-datepicker__time-box": {
                                                "@media (max-width: 400px)": {
                                                    "width": "100%",
                                                },
                                            },
                                            "& li.react-datepicker__time-list-item": {
                                                "display": "flex",
                                                "alignItems": "center",
                                                "&.react-datepicker__time-list-item--disabled": {
                                                    "display": "none",
                                                },
                                            },
                                            "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                            "& .react-datepicker__day--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                                "color": "#fff",
                                                "&:hover": {
                                                    "backgroundColor": theme.palette.primary.main,
                                                },
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "makeOfferBtn": { // options: src/components/MyAccount/property/components/makeOffer/defaults.js
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-jsonAutocompleteField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .myaccount-date-time-calendar": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                    "alignItems": "center",
                                    "& .react-datepicker": {
                                        "marginBottom": theme.spacing(3),
                                        "& .react-datepicker__month-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                            },
                                        },
                                        "& .react-datepicker__navigation--next": {
                                            "@media (max-width: 400px)": {
                                                "right": "2px",
                                            },
                                        },
                                        "& .react-datepicker__time-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                                "border": "none",
                                            },
                                            "& .react-datepicker__header.react-datepicker__header--time": {
                                                "@media (min-width: 401px)": {
                                                    "display": "flex",
                                                    "minHeight": "62px",
                                                    "alignItems": "center",
                                                    "justifyContent": "center",
                                                },
                                            },
                                            "& .react-datepicker__time-box": {
                                                "@media (max-width: 400px)": {
                                                    "width": "100%",
                                                },
                                            },
                                            "& li.react-datepicker__time-list-item": {
                                                "display": "flex",
                                                "alignItems": "center",
                                                "&.react-datepicker__time-list-item--disabled": {
                                                    "display": "none",
                                                },
                                            },
                                            "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                            "& .react-datepicker__day--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                                "color": "#fff",
                                                "&:hover": {
                                                    "backgroundColor": theme.palette.primary.main,
                                                },
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "bookViewingBtn": { // options: src/components/MyAccount/property/components/bookViewing/defaults.js
            "props": {
                // "apiCallFun": false
            },
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-jsonAutocompleteField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .myaccount-date-time-calendar": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                    "alignItems": "center",
                                    "& .react-datepicker": {
                                        "marginBottom": theme.spacing(3),
                                        "& .react-datepicker__month-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                            },
                                        },
                                        "& .react-datepicker__navigation--next": {
                                            "@media (max-width: 400px)": {
                                                "right": "2px",
                                            },
                                        },
                                        "& .react-datepicker__time-container": {
                                            "@media (max-width: 400px)": {
                                                "width": "100%",
                                                "border": "none",
                                            },
                                            "& .react-datepicker__header.react-datepicker__header--time": {
                                                "@media (min-width: 401px)": {
                                                    "display": "flex",
                                                    "minHeight": "62px",
                                                    "alignItems": "center",
                                                    "justifyContent": "center",
                                                },
                                            },
                                            "& .react-datepicker__time-box": {
                                                "@media (max-width: 400px)": {
                                                    "width": "100%",
                                                },
                                            },
                                            "& li.react-datepicker__time-list-item": {
                                                "display": "flex",
                                                "alignItems": "center",
                                                "&.react-datepicker__time-list-item--disabled": {
                                                    "display": "none",
                                                },
                                            },
                                            "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                            "& .react-datepicker__day--selected": {
                                                "backgroundColor": theme.palette.primary.main,
                                                "color": "#fff",
                                                "&:hover": {
                                                    "backgroundColor": theme.palette.primary.main,
                                                },
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "removePropertyPersona": { // options: src/components/MyAccount/property/components/removePropertyPersona/defaults.js
            "subthemes": {
                "dialogDefault": {
                    "props": {
                        "dialogTitleProps": {
                            "align": "left"
                        },
                    },
                    "sx": (theme) => ({
                        "& .dialogDefault-container": {
                        },
                        "& .dialogDefault-paper": {
                            [theme.breakpoints.up('md')]: {
                                "borderRadius": "6px",
                            },
                            "& .MuiDialogTitle-root": { // This is the dialog title container
                                "& .MuiTypography-root": { // This is the title
                                    "marginRight": "20px",
                                    "textAlign": "center",
                                },
                            },
                        },
                        "& .dialogDefault-paperFullScreen": {
                            "borderRadius": "6px 6px 0 0",
                        },
                        "& .dialogDefault-close": {
                            "position": "absolute",
                            "top": "20px",
                            "right": "20px",
                            "width": "24px",
                            "height": "24px",
                            "zIndex": 9999,
                        },
                        "& .dialogDefault-content": {}
                    })
                }
            }
        },
        "homeReportBtn": { // options: src/components/MyAccount/property/components/homeReport/defaults.js
        },
        "floatingContainer": { // options: src/components/MyAccount/floatingContainer/defaults.js
        },
        "fabBtn": { // options: src/components/MyAccount/fabBtn/defaults.js
            "props": {
                "defaultArea": defaultArea,
                "defaultSearchType": "sales",
            },
            "sx": (theme) => ({
                "width": "100%",
                "marginBottom": theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    "width": "auto",
                    "minWidth": "300px",
                },
                "& .box-container": {
                    "display": "flex",
                    "flexWrap": "wrap",
                    "alignItems": "center",
                    "border": "1px solid #DDDDDD",
                    "borderRadius": "4px",
                    "padding": theme.spacing(5),
                    "& .box-image": {
                        "background": theme.palette.grey.grey3,
                        "& svg": {
                            "fontSize": "2rem",
                            "color": theme.palette.primary.dark
                        }
                    },
                },
                "& .box-image-container": {
                    "display": "none",
                    [theme.breakpoints.up('lg')]: {
                        "display": "block"
                    },
                },
                "& .box-image-container, .box-btn-container, .box-content": {
                    [theme.breakpoints.down('lg')]: {
                        "flexBasis": "100%",
                        "maxWidth": "100%",
                    },
                },
                "& .box-content": {
                    [theme.breakpoints.down('lg')]: {
                        "padding": "10px 0",
                        "flexBasis": "100%",
                        "maxWidth": "100%",
                    },
                },
                "& .box-btn-container": {
                    "textAlign": "left",
                    "paddingTop": theme.spacing(1),
                    [theme.breakpoints.up('lg')]: {
                        "textAlign": "right",
                        "paddingTop": theme.spacing(0),
                    }
                },
                "& .box-container-title": {
                    "fontFamily": theme.primaryFontFamily,
                    "fontWeight": theme.typography.fontWeightBold,
                    "fontSize": theme.bodyMediumSize,
                    "lineHeight": theme.h5LineH,
                    "marginBottom": "5px",
                    "color": theme.palette.primary.dark,
                    [theme.breakpoints.up('xl')]: {
                        "marginBottom": "10px",
                    },
                },
                "& .box-container-text": {
                    "fontFamily": theme.secondaryFontFamily,
                    "fontSize": theme.bodyMedium,
                    "lineHeight": theme.lineHeightBMedium,
                    "fontWeight": "normal",
                    "color": theme.palette.grey.grey2,
                }
            }),
            "subthemes": {
                "globalForm": { // Overriding how the globalForm renders in action dialog
                    "props": {
                        "formButton": {
                            "variant": "contained",
                            "color": "primary",
                            "fullWidth": true,
                        },
                    },
                    "sx": (theme) => ({
                        "& .globalForm-globalFormForm": {
                            "width": "100%",
                        },
                        "& .globalForm-fieldContainer": {
                            "display": "flex",
                            "flexDirection": "column",
                        },
                        "& .globalForm-textField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(2.375),
                        },
                        "& .globalForm-selectField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(3),
                        },
                        "& .globalForm-jsonAutocompleteField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(3),
                        },
                        "& .myaccount-date-time-calendar": {
                            "display": "flex",
                            "flexDirection": "column",
                            "alignItems": "center",
                            "& .react-datepicker": {
                                "marginBottom": theme.spacing(3),
                                "& .react-datepicker__month-container": {
                                    "@media (max-width: 400px)": {
                                        "width": "100%",
                                    },
                                },
                                "& .react-datepicker__navigation--next": {
                                    "@media (max-width: 400px)": {
                                        "right": "2px",
                                    },
                                },
                                "& .react-datepicker__time-container": {
                                    "@media (max-width: 400px)": {
                                        "width": "100%",
                                        "border": "none",
                                    },
                                    "& .react-datepicker__header.react-datepicker__header--time": {
                                        "@media (min-width: 401px)": {
                                            "display": "flex",
                                            "minHeight": "62px",
                                            "alignItems": "center",
                                            "justifyContent": "center",
                                        },
                                    },
                                    "& .react-datepicker__time-box": {
                                        "@media (max-width: 400px)": {
                                            "width": "100%",
                                        },
                                    },
                                    "& li.react-datepicker__time-list-item": {
                                        "display": "flex",
                                        "alignItems": "center",
                                        "&.react-datepicker__time-list-item--disabled": {
                                            "display": "none",
                                        },
                                    },
                                    "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                        "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .react-datepicker__day--selected": {
                                        "backgroundColor": theme.palette.primary.main,
                                        "color": "#fff",
                                        "&:hover": {
                                            "backgroundColor": theme.palette.primary.main,
                                        },
                                    },
                                },
                            },
                        },
                        "& .suggestionsContainer": {
                            "position": "relative",
                            "& .suggestionsContainerOpen": {
                                "position": "absolute",
                                "zIndex": 998,
                                "top": "50px",
                                "left": 0,
                                "right": 0,
                            },
                            "& .suggestionsList": {
                                "margin": 0,
                                "padding": 0,
                                "listStyleType": "none",
                            },
                            "& .suggestion": {
                                "display": "block",
                                "padding": "10px 15px",
                                "&:hover": {
                                    "cursor": "pointer",
                                    "background": theme.palette.grey.grey3,
                                },
                            },
                        },
                        "& .globalForm-fieldsRadio": {
                            "marginBottom": theme.spacing(2),
                        },
                        "& .globalForm-actionContainer": {
                        },
                        "& .globalForm-fieldsTitle": {
                            "marginBottom": theme.spacing(3),
                        },
                        "& .globalForm-formButton": {
                            "width": "100%",
                        },
                    }),
                },
            },
        },
        "currentTenancy": { // options: src/components/MyAccount/currentTenancy/defaults.js
        },
        "payments": { // options: src/components/MyAccount/payments/defaults.js
        },
        "newsBannerImage": { // options: src/components/MyAccount/blog/components/bannerImage/defaults.js
        },
        "homePage": {
            "subthemes": {
                "tabsMyAccount": { // override styling for tabs on homepage subtheme
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up('lg')]: {
                                "borderBottom": "none",
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.primary.title,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "color": theme.palette.primary.title,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.primary.dark,
                                "fontWeight": theme.typography.fontWeightBold,
                            },
                            "&.tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(2.75,0,0,0),
                        }
                    }),
                },
            },
        },
        "homePage2": {
            "staticModules": [
                {
                    "imageBox": {
                        "title": "Our Services",
                        "moreLabel": null,
                        "moreLinkUrl": null,
                        "titleLinkMoreTooltipProps": null,
                        "items": [
                            {
                                "title": "Find a Property for Sale",
                                "subtitle": "Let’s find your new home together",
                                "linkUrl": "https://www.bentleyhurst.co.uk/property/for-sale/in-manchester/",
                                "imgUrl": `/images/${themeName}/find-a-property-for-sale.jpg`,
                                "imgAlt": "",
                            },
                            {
                                "title": "Find a Property to Rent",
                                "subtitle": "Let’s find your new home together",
                                "linkUrl": "https://www.bentleyhurst.co.uk/property/to-rent/in-manchester/",
                                "imgUrl": `/images/${themeName}/find-a-property-to-rent.jpg`,
                                "imgAlt": "",
                            },
                            {
                                "title": "Meet the Team",
                                "subtitle": "Speak to one of our experts",
                                "linkUrl": "https://www.bentleyhurst.co.uk/about-us/our-team/",
                                "imgUrl": `/images/${themeName}/meet-the-team.jpg`,
                                "imgAlt": "",
                            },
                        ],
                    },
                },
            ],
            "subthemes": {
                "tabsMyAccount": { // override styling for tabs on homepage subtheme
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up('lg')]: {
                                "borderBottom": "none",
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.primary.title,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "color": theme.palette.primary.title,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.primary.dark,
                                "fontWeight": theme.typography.fontWeightBold,
                            },
                            "&.tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(2.75,0,0,0),
                        }
                    }),
                },
                // "propertyResultComponent": {
                //     "subthemes": {
                //         "results": { // default property result styling
                //             "sx": (theme) => ({
                //                 [theme.breakpoints.up('sm')]: {
                //                     "width": "auto",
                //                     "margin": theme.spacing(0,-0.75),
                //                 },
                //                 [theme.breakpoints.up('lg')]: {
                //                     "margin": theme.spacing(0,-2.375),
                //                 },
                //             }),
                //             "subthemes": {
                //                 "propertyresult": {
                //                     "props": {
                //                         "container": {
                //                             "order": ['media', 'header', 'aside'],
                //                             "sx": (theme) => ({
                //                                 "flexDirection": "row",
                //                                 "width": "100%",
                //                                 "paddingTop": theme.spacing(1.125),
                //                                 "paddingBottom": theme.spacing(1.125),
                //                                 [theme.breakpoints.up('sm')]: {
                //                                     "flexDirection": "column",
                //                                     "width": `calc(50% - ${theme.spacing(1.5)})`,
                //                                     "paddingTop": 0,
                //                                     "paddingBottom": 0,
                //                                     "marginBottom": theme.spacing(5),
                //                                     "marginLeft": theme.spacing(0.75),
                //                                     "marginRight": theme.spacing(0.75),
                //                                 },
                //                                 [theme.breakpoints.up('lg')]: {
                //                                     "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                //                                     "marginLeft": theme.spacing(2.375),
                //                                     "marginRight": theme.spacing(2.375),
                //                                     "marginBottom": 0,
                //                                 },
                //                             }),
                //                         },
                //                         "header": {
                //                             "sx": (theme) => ({
                //                                 "width": "calc(100% - 110px)",
                //                                 [theme.breakpoints.up('sm')]: {
                //                                     "width": "100%",
                //                                     "marginTop": theme.spacing(3.25),
                //                                 },
                //                                 "& .propertyresult-address": {
                //                                     "fontWeight": "bold",
                //                                     [theme.breakpoints.up('sm')]: {
                //                                         "fontSize": theme.bodyLarge,
                //                                         "lineHeight": theme.lineHeightBLarge,
                //                                         "marginBottom": theme.spacing(0.25),
                //                                     },
                //                                     "& .propertyresult-link-image": {
                //                                         "color": "#292929",
                //                                     },
                //                                 },
                //                                 "& .propertyresult-price": {
                //                                     [theme.breakpoints.up('sm')]: {
                //                                         "fontSize": theme.bodyMedium,
                //                                         "lineHeight": theme.lineHeightBMedium,
                //                                     },
                //                                 },
                //                             }),
                //                         },
                //                         "price": {
                //                             "in": "header",
                //                             "variant": "bodySmall",
                //                         },
                //                         "address": {
                //                             "in": "header",
                //                             "variant": "bodyMedium",
                //                             "component": "header",
                //                         },
                //                         "offer": {
                //                             "in": "header",
                //                             "variant": "bodyMedium",
                //                         },
                //                         "rooms": {
                //                             "in": null,
                //                         },
                //                         // "save": {
                //                         //     "in": "aside",
                //                         // },
                //                         // "removeProperty": {
                //                         //     "in": "aside",
                //                         // },
                //                         "media": {
                //                             "sx": (theme) => ({
                //                                 "overflow": "hidden",
                //                                 "marginRight": theme.spacing(2.375),
                //                                 "&.propertyresult-media": {
                //                                     [theme.breakpoints.up('sm')]: {
                //                                         "width": "100%",
                //                                     },
                //                                 },
                //                                 "& .propertyresult-link-image": {
                //                                     "display": "block",
                //                                 },
                //                                 "& .propertyresult-image": {
                //                                     "display": "block",
                //                                     "width": "90px",
                //                                     [theme.breakpoints.up('sm')]: {
                //                                         "width": "100%",
                //                                     }
                //                                 },
                //                                 [theme.breakpoints.up('sm')]: {
                //                                     "marginRight": 0,
                //                                 }
                //                             })
                //                         },
                //                     },

                //                     // "typography": {
                //                     //     "h6": { "textTransform": "none" },
                //                     //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                //                     //     "subtitle2": { "textTransform": "none" },
                //                     // },

                //                     // "address": {
                //                     //     "separator": " • ",
                //                     //     "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                //                     // },
                //                 },
                //             },
                //         },
                //     },
                // },
                // "activityCard": {
                //     "subthemes": {
                //         "actionCard": {
                //             "sx": (theme) => ({
                //                 "&.actionCard-card": {
                //                     "marginBottom": 0,
                //                     "paddingTop": theme.spacing(1),
                //                     "paddingBottom": theme.spacing(1),
                //                     "&.disabled": {
                //                         "opacity": 0.5
                //                     }
                //                 },
                //                 "& .actionCard-cardActionButton": {
                //                     "backgroundColor": theme.palette.grey.grey4,
                //                     "color": theme.palette.primary.dark,
                //                 },
                //                 "& .actionCard-cardHeader": {
                //                     "padding": theme.spacing(1,0),
                //                     [theme.breakpoints.up('sm')]: {
                //                         "padding": theme.spacing(1.5,0),
                //                     },
                //                     "& .cardHeader-avatar": {
                //                         "marginLeft": "auto",
                //                         "marginRight": theme.spacing(1.875),
                //                         [theme.breakpoints.up('sm')]: {
                //                             "marginRight": theme.spacing(3.5),
                //                         }
                //                     },
                //                     "& .cardHeader-title": {
                //                         "fontSize": theme.bodySmall,
                //                         "marginBottom": theme.spacing(0.25),
                //                         [theme.breakpoints.up('sm')]: {
                //                             "fontSize": theme.bodyLarge,
                //                             "lineHeight": theme.lineHeightBLarge,
                //                             "marginBottom": theme.spacing(0.5),
                //                         },
                //                         [theme.breakpoints.up('lg')]: {
                //                             "fontSize": theme.bodySmall,
                //                             "lineHeight": theme.lineHeightBSmall,
                //                             "marginBottom": theme.spacing(0.25),
                //                         },
                //                     },
                //                     "& .cardHeader-subheader": {
                //                         "color": theme.palette.text.primary,
                //                         [theme.breakpoints.up('sm')]: {
                //                             "fontSize": theme.bodyMedium,
                //                             "lineHeight": theme.lineHeightBMedium,
                //                         },
                //                         [theme.breakpoints.up('lg')]: {
                //                             "fontSize": theme.bodyXSmall,
                //                             "lineHeight": theme.lineHeightBXSmall,
                //                         },
                //                     },
                //                     "& .cardHeader-action": {
                //                         "alignSelf": "center",
                //                         "marginRight": theme.spacing(0.5),
                //                     }
                //                 },
                //             })
                //         },
                //     },
                // },
            },
        },
        "myPropertyPage": {
            "props": {
                "tab":{
                    "default": MYACCOUNT_LIGHT ? "wishlist" : '',
                    // This can be a array or objects
                    "items": MYACCOUNT_LIGHT ? ['wishlist', 'saved-search'] : ['all-properties', 'selling', 'letting', 'renting', 'buying', 'wishlist', 'saved-search', 'archived'],
                }
            },
            "staticModules": [
                {
                    "videoText": {
                        "title": null,
                        "moreLabel": null,
                        "moreLinkUrl": null,
                        "titleLinkMoreTooltipProps": null,
                        "items": [
                            {
                                "containerProps": {
                                    "item": true,
                                    "spacing": 0,
                                    "flexDirection": ["column","column","row"],
                                },
                                "itemTextContainerProp": {
                                    "item": true,
                                    "xs": 12,
                                    "md": 8,
                                    "order": [1,1,1],
                                },
                                "itemImageContainerProps": {
                                    "item": true,
                                    "xs": 12,
                                    "md": 4,
                                    "order": [0,0,0],
                                },
                                "title": "Need a Mortgage?",
                                "text": "Having been around since 1981, we have an experienced and knowledgeable team of mortgage advisers you can wholeheartedly trust to give you best advice.",
                                "buttons": [
                                    {
                                        "type": "tel",
                                        "label": "Call us on +44 20 44334 8877",
                                        "url": "tel: +44 20 44334 8877",
                                        "icon": "PhoneIcon",
                                        "linkProps": {
                                            "color": "inherit",
                                        },
                                    },
                                    {
                                        "type": "email",
                                        "label": "or email us directly",
                                        "url": "mailto:hello@bentleyhurst.co.uk",
                                        "icon": "EmailIcon",
                                        "linkProps": {
                                            "color": "inherit",
                                        },
                                    }
                                ],
                                "linkUrl": "",
                                "imgUrl": `/images/${themeName}/need-mortgage.jpg`,
                                "imgAlt": "",
                                "videoUrl": "",
                                "lightboxType": "image", // Type of "image", "video", "youtube",
                            },
                        ],
                    },
                },
            ],
            "subthemes": {
                "tabsMyAccount": {
                    "props": {
                        "tabPanelBox": (theme) => ({
                            "padding": theme.spacing(2.75,0,0,0),
                            [theme.breakpoints.up('sm')]: {
                                "padding": theme.spacing(7,0,0,0),
                            },
                        }),
                    },
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.primary.title,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "color": theme.palette.primary.title,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),
                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom":  theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.primary.dark,
                                "fontWeight": theme.typography.fontWeightBold,
                            },
                            "&.tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(2.75,0,0,0),
                        }
                    }),
                },
            },
        },
        "myPropertyPageSeller": {
            "subthemes": {
                "mainContent": { // Remove top content spacing on mobile
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "column",
                        "width": "100%",
                        "flexGrow": 1,
                        "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                        [theme.breakpoints.up('sm')]: {
                            "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                        },
                        [theme.breakpoints.up('md')]: {
                            // "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`,
                            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                        },
                        "& .mainContent-wrapper": {
                            "flexGrow": 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                "tabsMyAccount": {
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "display": "none",
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            "marginTop": `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                                "marginTop": 0,
                            },
                            [theme.breakpoints.up('md')]: {
                                "display": "flex",
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.primary.title,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "color": theme.palette.primary.title,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),

                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom":  theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.primary.dark,
                                "fontWeight": theme.typography.fontWeightBold,
                            },
                            "&.tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(0,0,0,0),
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(7,0,0,0),
                            },
                        }
                    }),
                    "subthemes": {
                        "tabsMyAccount": {
                            "sx": (theme) => ({
                                "flexGrow": 1,
                                "marginBottom": theme.moduleSpacing,
                                [theme.breakpoints.up('sm')]: {
                                    "marginBottom": theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "marginBottom": theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    "display": "flex",
                                    "borderBottom": "1px solid #d8d8d8",
                                    "marginLeft": `-${theme.gridItemPmobile}`,
                                    "marginRight": `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up('sm')]: {
                                        "marginLeft": `-${theme.gridItemPtablet}`,
                                        "marginRight": `-${theme.gridItemPtablet}`,
                                        "paddingLeft": theme.gridItemPtablet,
                                        "paddingRight": theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "borderBottom": "none",
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                        "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        "height": "3px",
                                        "backgroundColor": theme.palette.primary.title,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        "alignItems": "flex-start",
                                        "& svg": {
                                            "marginTop": "12px",
                                        },
                                        "&.Mui-disabled": {
                                            "opacity": 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    "color": theme.palette.primary.title,
                                    "textTransform": "none",
                                    [theme.breakpoints.up('sm')]: {
                                        "minWidth": "auto",
                                        "paddingLeft": 0,
                                        "paddingRight": 0,
                                        "marginRight": theme.spacing(5),
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "paddingBottom":  theme.spacing(0.75),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        "color": theme.palette.primary.dark,
                                        "fontWeight": theme.typography.fontWeightBold,
                                    },
                                    "&.tabsMyAccount-wrapper": {
                                        "fontSize": theme.bodySmall,
                                        [theme.breakpoints.up('sm')]: {
                                            "fontSize": theme.bodyLarge,
                                        }
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    "padding": 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    "padding": theme.spacing(2.75,0,0,0),
                                    [theme.breakpoints.up('md')]: {
                                        "padding": theme.spacing(2.75,0,0,0),
                                    },
                                }
                            }),
                        },
                    },
                },
            },
        },
        "myPropertyPageLandlord": {
            "props": {
                "tab":{
                    // This can be a array or objects
                    "items": ['overview', 'tenancy', 'fault-and-issues', 'documents', 'appointments', 'feedback', 'transactions'],
                }
            },
            "subthemes": {
                "mainContent": { // Remove top content spacing on mobile
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "column",
                        "width": "100%",
                        "flexGrow": 1,
                        "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                        [theme.breakpoints.up('sm')]: {
                            "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                        },
                        [theme.breakpoints.up('md')]: {
                            "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                        },
                        "& .mainContent-wrapper": {
                            "flexGrow": 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                "tabsMyAccount": {
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "display": "none",
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            "marginTop": `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                                "marginTop": 0,
                            },
                            [theme.breakpoints.up('md')]: {
                                "display": "flex",
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.primary.title,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "color": theme.palette.primary.title,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),

                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom":  theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.primary.dark,
                                "fontWeight": theme.typography.fontWeightBold,
                            },
                            "&.tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(0,0,0,0),
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(7,0,0,0),
                            },
                        }
                    }),
                    "subthemes": {
                        "tabsMyAccount": {
                            "sx": (theme) => ({
                                "flexGrow": 1,
                                "marginBottom": theme.moduleSpacing,
                                [theme.breakpoints.up('sm')]: {
                                    "marginBottom": theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "marginBottom": theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    "display": "flex",
                                    "borderBottom": "1px solid #d8d8d8",
                                    "marginLeft": `-${theme.gridItemPmobile}`,
                                    "marginRight": `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up('sm')]: {
                                        "marginLeft": `-${theme.gridItemPtablet}`,
                                        "marginRight": `-${theme.gridItemPtablet}`,
                                        "paddingLeft": theme.gridItemPtablet,
                                        "paddingRight": theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "borderBottom": "none",
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                        "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        "height": "3px",
                                        "backgroundColor": theme.palette.primary.title,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        "alignItems": "flex-start",
                                        "& svg": {
                                            "marginTop": "12px",
                                        },
                                        "&.Mui-disabled": {
                                            "opacity": 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    "color": theme.palette.primary.title,
                                    "textTransform": "none",
                                    [theme.breakpoints.up('sm')]: {
                                        "minWidth": "auto",
                                        "paddingLeft": 0,
                                        "paddingRight": 0,
                                        "marginRight": theme.spacing(5),
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "paddingBottom":  theme.spacing(0.75),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        "color": theme.palette.primary.dark,
                                        "fontWeight": theme.typography.fontWeightBold,
                                    },
                                    "&.tabsMyAccount-wrapper": {
                                        "fontSize": theme.bodySmall,
                                        [theme.breakpoints.up('sm')]: {
                                            "fontSize": theme.bodyLarge,
                                        }
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    "padding": 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    "padding": theme.spacing(2.75,0,0,0),
                                    [theme.breakpoints.up('md')]: {
                                        "padding": theme.spacing(2.75,0,0,0),
                                    },
                                }
                            }),
                        },
                    },
                },
            },
        },
        "calendarPage": {
            "props": {
                "showContactInsteadOfValaution": false
            },
        },
        "inbox": {
        },
        "loginPage": {
        },
        "signUpPage": {
        },
        "unsubscribePage": {
        },
        "resetPassEmailPage": {
        },
        "resetPassPage": {
        },
        "profilePage": {
            "props": {
                "conversationalFormEnabled": true,
            //     "tab":{
            //         "default": "personal-details",
            //         // This can be a array or objects
            //         "items": ['personal-details', ...],
            //         "items": {
            //             'personal-details': {},
            //             ...
            //         },
            //     }
            },
            "subthemes": {
                "tabsMyAccount": {
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "display": "none",
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            "marginTop": `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                                "marginTop": 0,
                            },
                            [theme.breakpoints.up('md')]: {
                                "display": "flex",
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.primary.title,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "color": theme.palette.primary.title,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),

                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom":  theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.primary.dark,
                                "fontWeight": theme.typography.fontWeightBold,
                            },
                            "&.tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(0,0,0,0),
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(5,0,0,0),
                            },
                        }
                    }),
                },
                "profileCard": {
                    "sx": (theme) => ({
                        "marginBottom": theme.spacing(3.75),
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.spacing(4),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.spacing(8),
                        },
                        "& .profileCard-header": {
                            "flexDirection": "column",
                            "padding": 0,
                            [theme.breakpoints.up('md')]: {
                                "flexDirection": "row",
                            },
                            "& .profileCard-avatar": {
                                "marginRight": 0,
                                [theme.breakpoints.up('sm')]: {
                                    "marginRight": 0,
                                },
                            },
                            "& .profileCard-avatar-img": {
                                "marginRight": 0,
                                "marginBottom": theme.spacing(1),
                                // "marginRight": theme.spacing(2.5),
                                [theme.breakpoints.up('md')]: {
                                    "marginRight": theme.spacing(3.75),
                                    "marginBottom": 0,
                                },
                            },
                            "& .profileCard-title": {
                                "fontWeight": 400,
                                "fontSize": theme.h5,
                                "lineHeight": theme.h5LineHm,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.h3,
                                    "lineHeight": theme.h3LineHm,
                                }
                            },
                            "& .profileCard-subheader": {
                                "color": theme.palette.text.primary,
                            },
                        },
                        "& .cardHeaderCaption": {
                            "color": theme.palette.grey.grey2,
                            [theme.breakpoints.up('lg')]: {
                                "display": "none",
                            }
                        },
                    }),
                },
            },
        },
        "myPropertyPageTenant": {
            "subthemes": {
                "mainContent": { // Remove top content spacing on mobile
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "column",
                        "width": "100%",
                        "flexGrow": 1,
                        "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                        [theme.breakpoints.up('sm')]: {
                            "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                        },
                        [theme.breakpoints.up('md')]: {
                            "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                        },
                        "& .mainContent-wrapper": {
                            "flexGrow": 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                "tabsMyAccount": {
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "display": "none",
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            "marginTop": `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                                "marginTop": 0,
                            },
                            [theme.breakpoints.up('md')]: {
                                "display": "flex",
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.primary.title,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "color": theme.palette.primary.title,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),

                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom":  theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.primary.dark,
                                "fontWeight": theme.typography.fontWeightBold,
                            },
                            "&.tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(0,0,0,0),
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(4,0,0,0),
                            },
                        }
                    }),
                    "subthemes": {
                        "tabsMyAccount": {
                            "sx": (theme) => ({
                                "flexGrow": 1,
                                "marginBottom": theme.moduleSpacing,
                                [theme.breakpoints.up('sm')]: {
                                    "marginBottom": theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "marginBottom": theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    "display": "flex",
                                    "borderBottom": "1px solid #d8d8d8",
                                    "marginLeft": `-${theme.gridItemPmobile}`,
                                    "marginRight": `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up('sm')]: {
                                        "marginLeft": `-${theme.gridItemPtablet}`,
                                        "marginRight": `-${theme.gridItemPtablet}`,
                                        "paddingLeft": theme.gridItemPtablet,
                                        "paddingRight": theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "borderBottom": "none",
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                        "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        "height": "3px",
                                        "backgroundColor": theme.palette.primary.title,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        "alignItems": "flex-start",
                                        "& svg": {
                                            "marginTop": "12px",
                                        },
                                        "&.Mui-disabled": {
                                            "opacity": 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    "color": theme.palette.primary.title,
                                    "textTransform": "none",
                                    [theme.breakpoints.up('sm')]: {
                                        "minWidth": "auto",
                                        "paddingLeft": 0,
                                        "paddingRight": 0,
                                        "marginRight": theme.spacing(5),
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "paddingBottom":  theme.spacing(0.75),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        "color": theme.palette.primary.dark,
                                        "fontWeight": theme.typography.fontWeightBold,
                                    },
                                    "&.tabsMyAccount-wrapper": {
                                        "fontSize": theme.bodySmall,
                                        [theme.breakpoints.up('sm')]: {
                                            "fontSize": theme.bodyLarge,
                                        }
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    "padding": 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    "padding": theme.spacing(2.75,0,0,0),
                                    [theme.breakpoints.up('md')]: {
                                        "padding": theme.spacing(2.75,0,0,0),
                                    },
                                }
                            }),
                        },
                    },
                },
            },
        },
        "myPropertyPageBuyer": {
            "subthemes": {
                "mainContent": { // Remove top content spacing on mobile
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "column",
                        "width": "100%",
                        "flexGrow": 1,
                        "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                        [theme.breakpoints.up('sm')]: {
                            "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                        },
                        [theme.breakpoints.up('md')]: {
                            "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                        },
                        "& .mainContent-wrapper": {
                            "flexGrow": 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                "tabsMyAccount": {
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "display": "none",
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            "marginTop": `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                                "marginTop": 0,
                            },
                            [theme.breakpoints.up('md')]: {
                                "display": "flex",
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.primary.title,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "color": theme.palette.primary.title,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),

                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom":  theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.primary.dark,
                                "fontWeight": theme.typography.fontWeightBold,
                            },
                            "&.tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(0,0,0,0),
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(7,0,0,0),
                            },
                        }
                    }),
                    "subthemes": {
                        "tabsMyAccount": {
                            "sx": (theme) => ({
                                "flexGrow": 1,
                                "marginBottom": theme.moduleSpacing,
                                [theme.breakpoints.up('sm')]: {
                                    "marginBottom": theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "marginBottom": theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    "display": "flex",
                                    "borderBottom": "1px solid #d8d8d8",
                                    "marginLeft": `-${theme.gridItemPmobile}`,
                                    "marginRight": `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up('sm')]: {
                                        "marginLeft": `-${theme.gridItemPtablet}`,
                                        "marginRight": `-${theme.gridItemPtablet}`,
                                        "paddingLeft": theme.gridItemPtablet,
                                        "paddingRight": theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "borderBottom": "none",
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                        "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        "height": "3px",
                                        "backgroundColor": theme.palette.primary.title,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        "alignItems": "flex-start",
                                        "& svg": {
                                            "marginTop": "12px",
                                        },
                                        "&.Mui-disabled": {
                                            "opacity": 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    "color": theme.palette.primary.title,
                                    "textTransform": "none",
                                    [theme.breakpoints.up('sm')]: {
                                        "minWidth": "auto",
                                        "paddingLeft": 0,
                                        "paddingRight": 0,
                                        "marginRight": theme.spacing(5),
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "paddingBottom":  theme.spacing(0.75),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        "color": theme.palette.primary.dark,
                                        "fontWeight": theme.typography.fontWeightBold,
                                    },
                                    "&.tabsMyAccount-wrapper": {
                                        "fontSize": theme.bodySmall,
                                        [theme.breakpoints.up('sm')]: {
                                            "fontSize": theme.bodyLarge,
                                            "paddingBottom":  theme.spacing(2.5),
                                        }
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    "padding": 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    "padding": theme.spacing(2.75,0,0,0),
                                    [theme.breakpoints.up('md')]: {
                                        "padding": theme.spacing(2.75,0,0,0),
                                    },
                                }
                            }),
                        },
                    },
                },
            },
        },
        "propertyResultsPageFilters": { // options: src/components/MyAccount/pages/properties/defaults.js
        },
        "propertyResultsPage": {
            "subthemes": {
                "results": { // default property result styling
                    "sx": (theme) => ({
                        [theme.breakpoints.up('sm')]: {
                            "width": "auto",
                            "margin": theme.spacing(0,-0.75),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "margin": theme.spacing(0,-2.375),
                        },
                    }),
                    "subthemes": {
                        "propertyresult": {
                            "props": {
                                "container": {
                                    "order": ['media', 'header', 'aside'],
                                    "sx": (theme) => ({
                                        "flexDirection": "column",
                                        "width": "100%",
                                        "marginBottom": theme.spacing(3),
                                        [theme.breakpoints.up('sm')]: {
                                            "width": `calc(50% - ${theme.spacing(1.5)})`,
                                            "marginBottom": theme.spacing(5),
                                            "marginLeft": theme.spacing(0.75),
                                            "marginRight": theme.spacing(0.75),
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                                            "marginLeft": theme.spacing(2.375),
                                            "marginRight": theme.spacing(2.375),
                                        },
                                    }),
                                },
                                "header": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "marginTop": theme.spacing(1.5),
                                        [theme.breakpoints.up('sm')]: {
                                            "marginTop": theme.spacing(3.25),
                                        },
                                        "& .propertyresult-address": {
                                            "fontWeight": "bold",
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyLarge,
                                                "lineHeight": theme.lineHeightBLarge,
                                                "marginBottom": theme.spacing(0.25),
                                            },
                                            "& .propertyresult-link-image": {
                                                "color": "#292929",
                                            },
                                        },
                                        "& .propertyresult-rooms": {
                                            // "paddingBottom": theme.spacing(1.25),
                                        },
                                    }),
                                },
                                "aside": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "display": "flex",
                                        "flexDirection": "row",
                                        "alignItems": "center",
                                        "justifyContent": "flex-end",
                                        "& .propertyresult-price": {
                                            "marginRight": "auto",
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyMedium,
                                                "lineHeight": theme.lineHeightBMedium,
                                            },
                                        },
                                    }),
                                },
                                "price": {
                                    "in": "aside",
                                    "variant": "bodySmall",
                                    "component": "p",
                                },
                                "address": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                    "component": "header",
                                },
                                "offer": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                },
                                "rooms": {
                                    "in": "header",
                                },
                                "bookviewing": {
                                    "in": "aside",
                                },
                                "save": {
                                    "in": "media",
                                },
                                "360tour": {
                                    "in": "media"
                                },
                                // "removeProperty": {
                                //     "in": "aside",
                                // },
                                "media": {
                                    "sx": (theme) => ({
                                        "position": "relative",
                                        "overflow": "hidden",
                                        "& .propertyresult-link-image": {
                                            "display": "block",
                                            "&:after": {
                                                "content": "''",
                                                "position": "absolute",
                                                "top": 0,
                                                "left": 0,
                                                "width": "100%",
                                                "height": "100%",
                                                "background": "rgba(0,0,0,0.2)",
                                                "zIndex": "2",
                                                "pointerEvents": "none",
                                            },
                                        },
                                        "& .propertyresult-image": {
                                            "display": "block",
                                            "width": "100%",
                                        },
                                        "& .propertyresult-save": {
                                            "position": "absolute",
                                            "top": "10px",
                                            "right": "10px",
                                            "zIndex": "20",
                                            "& button": {
                                                "width": "36px",
                                                "height": "36px",
                                                "background": "white",
                                                "borderRadius": "100%",
                                                "& svg": {
                                                    "width": "18px",
                                                    "height": "18px",
                                                },
                                                "&:hover": {
                                                    "background": theme.palette.primary.main,
                                                    "& svg": {
                                                        "color": "#fff",
                                                    },
                                                    "@media (hover: none)": {
                                                        "background": "white",
                                                        "& svg": {
                                                            "color": "inherit",
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                        "& .propertyresult-360tour": {
                                            "position": "absolute",
                                            "bottom": "15px",
                                            "right": "15px",
                                            "width": "auto",
                                            "zIndex": 20,
                                        },
                                    })
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            "addressFormat": {
                                "separator": " , ",
                                "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift()
                                // "values": (p) => console.log(p)
                            },
                            "roomsFormat": {
                                "separator": " ",
                                "values": (p) => [p.bedrooms,p.receptions,p.bathrooms]
                            },
                            "linkFormat": {
                                "separator": "-",
                                "target":"_blank",
                                "values": (p) => propertyLink(p)
                                // "values": (p) => console.log(p)
                            },
                            // "lettingsPricePrefix": "",
                            "lettingsPriceSuffix": " PCM",
                            // "salesPricePrefix": "",
                            // "salesPriceSuffix": "",
                        },
                    },
                },
                "propertyAreaInfo": { // options: src/components/MyAccount/property/components/areaInfo/defaults.js
                },
                "propertyDetailsRooms": {
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "row",
                        "& .roomsItem": {
                            "display": "flex",
                            "flexDirection": "row",
                            "marginRight": theme.spacing(2.25),
                            [theme.breakpoints.up('sm')]: {
                                "marginRight": theme.spacing(3.25),
                            },
                            "& svg": {
                                "marginRight": theme.spacing(1),
                                "color": "#d1d2d3",
                            },
                            "& .roomsItem-text": {

                            },
                        },
                    }),
                },
                "bookViewingBtn": {
                    "props": {
                        // "apiCallFun": false,
                        "btnLabelProps": "Book viewing online",
                        "showBtnIcon": true,
                        "btnProps": {
                            "color": "primary",
                            "variant": "text",
                            "size": "small",
                        }
                    }
                },
            },
        },
        "propertyResultsMapPage": {
            "props" : {
                "linkFormat": {
                    "separator": "-",
                    "target":"_blank",
                    "values": (p) => propertyLink(p)
                    // "values": (p) => console.log(p)
                }
            }
        },
        "carousel": { // options: src/components/MyAccount/carousel/defaults.js
        },
        "propertyAreaInfo": { // options: src/components/MyAccount/property/components/areaInfo/defaults.js
        },
        "propertyDetailsRooms": { // options: src/components/MyAccount/property/components/rooms/defaults.js
        },
        "locratingIframe": { // options: src/components/MyAccount/property/components/map/defaultsLocratingMap.js
        },
        "videoTour": { // options: src/components/MyAccount/property/components/videoTour/defaults.js
        },
        "virtualTour": { // options: src/components/MyAccount/property/components/virtualTour/defaults.js
        },
        "performanceTabs": { // options: src/components/MyAccount/pages/my-property/common/performance/defaults.js
        },
        "facebookPerformance": { // options: src/components/MyAccount/pages/my-property/common/performance/facebook/defaults.js
        },
        "performanceChart": { // options: src/components/MyAccount/pages/my-property/common/performance/performanceChart/defaults.js
        },
        "lineChart": { // options: src/components/MyAccount/charts/lineChart/defaults.js
        },
        "propertyDetailsPage": {
            "subthemes": {
                "globalPageTitle": {
                    "sx": (theme) => ({
                        "display": "none",
                        "fontWeight": "normal",
                        [theme.breakpoints.up('md')]: {
                            "display": "block",
                            "marginBottom": theme.spacing(1),
                        },
                    }),
                },
                "propertyDetailsRooms": {
                    "props": {
                        "propertyBedsText": "Bedrooms",
                        "propertyBedsSingleText": "Bedroom",
                        "propertyBathsText": "Bathrooms",
                        "propertyBathsSingleText": "Bathroom",
                        "propertyRecText": "Receptions",
                        "propertyRecSingleText": "Reception",
                    },
                    "sx": (theme) => ({
                        "marginBottom": theme.spacing(2.25),
                        "display": "flex",
                        "flexDirection": "column",
                        [theme.breakpoints.up('sm')]: {
                            "flexDirection": "row",
                            "marginBottom": theme.spacing(3.25),
                        },
                        [theme.breakpoints.up('md')]: {
                            "marginBottom": theme.spacing(4.25),
                        },
                        "& .roomsItem": {
                            "display": "flex",
                            "flexDirection": "row",
                            [theme.breakpoints.up('sm')]: {
                                "marginRight": theme.spacing(3.25),
                            },
                            "& svg": {
                                "marginRight": theme.spacing(1),
                                "color": "#d1d2d3",
                            },
                            "& .roomsItem-text": {
                                "fontSize": theme.bodySmall,
                                "lineHeight": theme.lineHeightBSmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyMedium,
                                    "lineHeight": theme.lineHeightBMedium,
                                },
                            },
                        },
                    }),
                },
                "mainContent": { // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "column",
                        "width": "100%",
                        "flexGrow": 1,
                        "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                        [theme.breakpoints.up('sm')]: {
                            "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                        },
                        [theme.breakpoints.up('md')]: {
                            "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`,
                            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                        },
                        "& .mainContent-wrapper": {
                            "flexGrow": 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
            },
            // "lettingsPricePrefix": "",
            "lettingsPriceSuffix": " PCM",
            // "salesPricePrefix": "",
            // "salesPriceSuffix": "",
        },
        "newsOverviewItem": {
        },
        "newsOverviewPage": {
        },
        "newsDetailsPage": { // options: src/components/MyAccount/blog/details/defaults.js
            "subthemes": {
                "globalPageTitle": {
                    "sx": (theme) => ({
                        "display": "block",
                        "fontWeight": "normal",
                        "fontSize": theme.h5,
                        "lineHeight": theme.h5LineH,
                        "marginBottom": theme.spacing(0),
                        [theme.breakpoints.up('md')]: {
                            "fontSize": theme.h4,
                            "lineHeight": theme.h4LineH,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "fontSize": theme.h3,
                            "lineHeight": theme.h3LineH,
                        },
                    }),
                },
            },
        },
        "404Page": { // options: src/components/MyAccount/404page/defaults.js
        },
        "myaccountFooter": { // options: src/components/MyAccount/footer/defaults.js
            "props": {
                "_footerMenus": [
                    {
                        "id": "link1",
                        "name": "Terms and Conditions",
                        "target": "_blank",
                        "url": termsSiteLink,
                    },
                    // {
                    //     "id": "link2",
                    //     "name": "Privacy Policy & Notice",
                    //     "target": "_blank",
                    //     "url": privacySiteLink,
                    // }
                ],
            },
        },
    },
}
